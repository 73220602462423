import React from 'react';
import Chart from 'react-apexcharts';
import useMediaQueries from '~/SkillsAssessment/hooks/useMediaQueries';

const categories = [
	'Gestão de Negócios',
	['Marketing e', 'Growth'],
	'Vendas',
	['Cultura e', 'Liderança'],
	['Experiência', 'do cliente'],
];

const RadarChart = ({ series, height = '475px' }) => {
	const { isBetweenXsAndSm: isMobile } = useMediaQueries();
	React.useEffect(() => {}, []);

	const options = {
		chart: {
			type: 'radar',
			toolbar: { show: false },
		},
		fill: {
			colors: ['#FF6559'],
			type: 'solid',
		},
		stroke: {
			show: true,
			curve: 'smooth',
			lineCap: 'butt',
			colors: ['#FF6559'],
			width: 3,
			dashArray: 0,
		},
		dataLabels: {
			enabled: true,
			background: {
				enabled: true,
				borderRadius: 5,
			},
			dropShadow: {
				enabled: true,
				top: 1,
				left: 1,
				blur: 4,
				color: 'red',
				opacity: 1,
				width: 50,
				height: 50,
			},
		},
		plotOptions: {
			radar: {
				size: isMobile ? 100 : 200,
				polygons: {
					strokeColors: 'rgba(117, 117, 117, 0.5)',
					fill: {
						colors: [
							'rgba(255, 255, 255, 0.05)',
							'rgba(255, 255, 255, 0.05)',
							'rgba(255, 255, 255, 0.07)',
							'rgba(255, 255, 255, 0.1)',
						],
					},
				},
			},
		},
		colors: ['#FF6559'],
		markers: {
			size: 0,
			colors: ['#fff'],
			strokeColor: '#FF6559',
			strokeWidth: 2,
			dropShadow: {
				enabled: true,
				top: 5,
				left: 5,
				blur: 4,
				color: '#FF6559',
				opacity: 1,
			},
		},
		yaxis: {
			show: false,
			tickAmount: 4,
			min: 0,
			max: 100,
		},
		xaxis: {
			categories: categories,
			labels: {
				show: true,
				trim: true,
				style: {
					colors: categories?.map(() => '#fff'),
					fontSize: '10px',
					fontFamily: 'Arial',
					fontWeight: 600,
					wordWrap: 'normal',
					whiteSpace: 'pre-line',
					wordBreak: 'break-all',
					width: '20px',
				},
			},
		},
	};

	return (
		<Chart
			options={options}
			series={[{ data: series }]}
			type="radar"
			height={isMobile ? '365px' : height}
			width="100%"
		/>
	);
};

export default RadarChart;
