import React from 'react';
import { Btn, BtnWhatsapp } from './Button.style.jsx';
import WhatsApp from '@material-ui/icons/WhatsApp';

const Button = ({
	id,
	text,
	onClick,
	backIcon = false,
	forwardIcon = false,
	disabled = false,
	size = 'large',
}) => {

	if (text.toLowerCase() === 'whatsapp') {
		return (
			<BtnWhatsapp
				id={id}
				size={size}
				onClick={onClick}
				startIcon={backIcon ? backIcon : null}
				endIcon={forwardIcon ? forwardIcon : null}
				disabled={disabled}
			>
				<WhatsApp/> FALE COM UM ESPECIALISTA
			</BtnWhatsapp>
		)
	}

	return (
		<Btn
			id={id}
			size={size}
			onClick={onClick}
			startIcon={backIcon ? backIcon : null}
			endIcon={forwardIcon ? forwardIcon : null}
			disabled={disabled}
		>
			{text}
		</Btn>
	)
};

export default Button;
