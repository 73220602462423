import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import withConnect from 'stores/withConnect';
import Finish from './Finish';
import { CircularProgress, Backdrop } from '@material-ui/core';
import { getLocalStorage, removeLocalStorage } from 'utils/localStorage';
import { api } from '../../services/api';

const FinishContainer = () => {
	const [loading, setLoading] = useState(true);
	const [assessment, setAssessment] = useState(null);
	const [result, setResult] = useState(null);
	const [chartData, setChartData] = useState(null);
	const [lead, setLead] = useState({});
	const [link, setLink] = useState('');
	const [links, setLinks] = useState([]);
	const [buttonText, setButtonText] = useState('');
	const [diagnostics, setDiagnostics] = useState([]);
	const { uuid } = useParams();

	async function getScore() {
		try {
			const finalResult = await api.get(`/answers?leadUuid=${uuid}`);
			const { result, diagnostics, lead, percents, types, links, previous_result, assessment_name, assessment_id } = finalResult.data;
			const link = await getLocalStorage('linkUrl');
			const buttonText = await getLocalStorage('buttonText');
			const series = [
				{
					name: 'Pontuação',
					data: percents,
				}
			];

			// if (previous_result) {
			// 	series.push({
			// 		name: 'Pontuação Anterior',
			// 		data: previous_result.percents,
			// 	})
			// }

			const chartData = {
				series,
				options: {
					chart: {
						type: 'radar',
						foreColor: '#475059',
						toolbar: {
							show: false,
						},
					},
					dataLabels: {
						enabled: true,
					},
					plotOptions: {
						radar: {
							size: 178,
							polygons: {
								strokeColors: '#b8b8b8',
								fill: {
									colors: undefined,
									opacity: 0.9,
								},
							},
						},
					},
					colors: ['#F04D57', '#475059'],
					markers: {
						size: 4,
						colors: ['#fff', '#fff'],
						strokeColor: ['#F04D57', '#475059'],
						strokeWidth: 2,
					},
					tooltip: {
						enabled: false,
					},
					xaxis: {
						categories: types,
					},
					yaxis: {
						tickAmount: 7,
						labels: {
							formatter: function (val, i) {
								if (i % 2 === 0) {
									return val;
								} else {
									return '';
								}
							},
						},
					},
					responsive: [
						{
							breakpoint: 900,
							options: {
								plotOptions: {
									radar: {
										size: 82,
									},
								},
							},
						},
						{
							breakpoint: 900,
							options: {
								plotOptions: {
									radar: {
										size: 82,
									},
								},
							},
						},
					],
				},
			};

			setAssessment({
				assessment_id,
				assessment_name
			});
			setResult(result);
			setLead(lead);
			setLink(link);
			setLinks(links);
			setButtonText(buttonText);
			setDiagnostics(diagnostics);
			setChartData(chartData);
			await clearStorage();
			setLoading(false);
		} catch (error) {
			console.error(error);
		}
	}

	async function clearStorage() {
		try {
			removeLocalStorage('utm_date');
			removeLocalStorage('lead_id');
			removeLocalStorage('subscription_id');
		} catch (error) {
			console.log(error);
		}
	}

	useEffect(() => {
		if (!result) getScore();
	}, []);

	return loading ? (
		<Backdrop open={loading}>
			<CircularProgress color="inherit" />
		</Backdrop>
	) : (
		<Finish
			chartData={chartData}
			assessment={assessment}
			result={result}
			lead={lead}
			link={link}
			links={links}
			buttonText={buttonText}
			diagnostics={diagnostics}
		/>
	);
};

export default withConnect(FinishContainer);
