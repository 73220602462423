import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { Title, Text } from './Terms.style';
import DOMPurify from 'dompurify';

const Terms = ({ text }) => {
	const Html = DOMPurify.sanitize(text.content);
	return (
		<Box>
			<Grid
				container
				direction="row"
				justify="space-around"
				alignItems="center"
			>
				<Grid container item xs={12} sm={8} m={3}>
					<Box m={5} justifyContent={'left'}>
						<Title>{text.title}</Title>
						<Text>
							<div dangerouslySetInnerHTML={{ __html: Html }} />
						</Text>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
};

export default Terms;
