import React from 'react';
import {
	Grid,
	RadioGroup,
	FormControl,
	FormControlLabel,
	FormLabel,
} from '@material-ui/core';
import {
	G4Label,
	G4Input,
	G4RadioButton,
	G4Checkobx,
	Number,
} from './Question.style.jsx';

const Question = ({
	id,
	number,
	text,
	type,
	options = null,
	setAnswer,
	getAnswer,
	check,
	handleCheck,
	onKeyPress,
	handleChange,
}) => {
	return (
		<>
			{options ? (
				<FormControl
					fullWidth
					variant="filled"
					id="form-question-option"
					onKeyPress={onKeyPress}
					style={{
						alignItems: 'left',
						justifyContent: 'center',
						marginTop: '2%',
						marginBottom: '2%',
					}}
				>
					<Grid>
						<G4Label>
							<Number>{number} - </Number> {text}
						</G4Label>
						<RadioGroup
							aria-label="question"
							id="radio-question"
							name="radio-question"
							style={{
								marginTop: 15,
							}}
						>
							{options.map((item) => (
								<FormControlLabel
									key={item.id}
									id={item.id}
									value={item.value}
									label={item.value}
									control={
										<G4RadioButton
											onChange={(e) => {
												handleChange(e, type, item.score, id, item.id);
											}}
										/>
									}
									style={
										item.value.length > 50
											? { marginBottom: '3px', alignItems: 'center' }
											: { marginBottom: '0px' }
									}
								/>
							))}
						</RadioGroup>
					</Grid>
				</FormControl>
			) : (
				<FormControl
					id="form-question-text"
					fullWidth
					variant="filled"
					onKeyPress={onKeyPress}
				>
					<FormLabel
						htmlFor="filled-adornment-amount"
						style={{ textAlign: 'justify' }}
					>
						<G4Label id="label-question">
							<Number>{number} - </Number> {text}
						</G4Label>
					</FormLabel>
					<G4Input
						id="input-question"
						placeholder=" Responda aqui..."
						variant="outlined"
						fullWidth
						margin="normal"
						InputLabelProps={{
							shrink: true,
						}}
						onChange={(value) => setAnswer(id, value)}
						value={getAnswer(id)}
					/>
					{id === 3 && (
						<FormControlLabel
							control={
								<G4Checkobx
									checked={check}
									onChange={handleCheck}
									name="contact-checkbox"
									id="contact-checkbox"
								/>
							}
							label="Aceito receber contato neste número."
						/>
					)}
				</FormControl>
			)}
		</>
	);
};

export default Question;
