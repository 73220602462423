export function getOrigin() {
	return process.env.REACT_APP_ORIGIN;
}

export function checkKey(key) {
	if (key === 'maturidade') return 1;
	if (key === 'growth') return 2;
	if (key === 'growth-conf') return 3;
	if (key === 'maturidade-inicial') return 4;
	if (key === 'growth-online') return 5;
	if (key === 'traction') return 6;
	if (key === 'sdr') return 7;
	if (key === 'conarh') return 9;
	if (key === 'g4pass') return 10;
	if (key === 'lideranca') return 11;
	if (key === 'gestao-de-pessoas') return 12;
	if (key === 'gente-e-cultura') return 13;
	if (key === 'skills') return 14;
	if (!key) return 1;
	return 0;
}

export function triggerEvent(event_type) {
	// GOOGLE TAG MANAGER EVENTS
	if (process.env.REACT_APP_NODE_ENV === 'production') {
		window.dataLayer.push({
			event: event_type,
		});
	}
}
