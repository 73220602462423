import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import withConnect from 'stores/withConnect';
import Result from './Result';
import { CircularProgress, Backdrop } from '@material-ui/core';
import { getLocalStorage, removeLocalStorage } from 'utils/localStorage';
import { api } from '../../services/api';
import { checkKey } from '../../utils/general';

const ResultContainer = () => {
	const [loading, setLoading] = useState(true);
	const [results, setResults] = useState(null);
	const { key, email } = useParams();

	async function getScore() {
		try {
			const resultList = await api.get(`/results/${checkKey(key)}/${email}`);
			console.log(`/results/${checkKey(key)}/${email}`, resultList);
			setResults(resultList.data);
			await clearStorage();
			setLoading(false);
		} catch (error) {
			console.error(error);
		}
	}

	async function clearStorage() {
		try {
			removeLocalStorage('utm_date');
			removeLocalStorage('lead_id');
			removeLocalStorage('subscription_id');
		} catch (error) {
			console.log(error);
		}
	}

	useEffect(() => {
		if (!results) getScore();
	}, []);

	

	return (<div style={{minHeight:150, backgroundColor:"#233140"}}> {loading ? (
			<div style={{padding:"40px 0"}}><center><CircularProgress color="inherit" /></center></div>
	) : (
		<Result
			results={results} />
	)} </div>)
};

export default withConnect(ResultContainer);
