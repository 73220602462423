import { CardMedia } from '@material-ui/core';
import React from 'react';
import PolygonBackgroundSvg from '../../../assets/polygon-background.svg';
import { ContainerStyled, ContentStyled } from './ContainerWrapper.styles';

const ContainerWrapper = ({
	children,
	noBackgroundImage = false,
	...props
}) => {
	React.useEffect(() => {}, []);

	if (noBackgroundImage) {
		return <ContainerStyled {...props}>{children}</ContainerStyled>;
	}

	return (
		<ContainerStyled {...props}>
			<CardMedia
				component="img"
				alt="polygon-background"
				image={PolygonBackgroundSvg}
				style={{
					width: 'auto',
					height: 'auto',
					position: 'fixed',
					bottom: -10,
					right: -10,
				}}
			/>
			<ContentStyled {...props}>{children}</ContentStyled>
		</ContainerStyled>
	);
};

export default ContainerWrapper;
