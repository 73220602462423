export const dataScore = [
	{
		id: 1,
		categorie: 'Experiência do Cliente',
		diagnosticResult: [
			{
				result: 'negative',
				yourCompanyToday: `
					Ter clareza sobre a jornada completa do seu cliente não apenas
					favorece a experiência dele e a percepção de sua marca, como também
					pode se traduzir em uma excelente oportunidade para melhorar
					continuamente as práticas do seu negócio.
					É preciso entender que o cliente é o ponto chave para o
					sucesso de gigantes como a Amazon, cujo primeiro princípio de
					liderança é a "obsessão pelo cliente". Não levar isso em conta pode
					trazer consequências nocivas como baixa retenção, aumento de custo
					para aquisição de clientes e perda de competitividade, por exemplo.
				`,
				whatToDo: `
					Tenha em mente que para construir uma boa jornada do cliente, não é
					preciso alto investimento: pequenas mudanças no processo já podem
					aumentar consideravelmente a satisfação do seu cliente. Comece pelo
					básico: entenda o que está dando certo e mapeie o que não está dando
					certo. Identifique o que mais está impactando a experiência e
					satisfação do cliente, e ataque de forma exclusiva. Você possui
					vários pontos para corrigir, mas é importante começar de alguma
					forma. Entenda este gargalo e concentre esforços.
				`,
			},
			{
				result: 'regular',
				yourCompanyToday: `
					Você já percebeu a importância que a experiência do cliente tem para
					o fortalecimento de sua marca e a melhoria constante do seu negócio.
					Entretanto, quais são as estratégias que você vem usando para
					aprimorar a experiência dos seus clientes? Seus clientes voltam a
					comprar? Quais indicadores e métricas você aplica para ter um
					panorama real e implantar melhorias? Você já possui clientes que
					provavelmente gostam e recomendam a sua solução. Da mesma forma que
					possui clientes insatisfeitos e que cobram melhoria, e tudo bem. É a
					realidade de todo negócio e o primeiro caminho para saber por onde
					começar é ter consciência de quem são e quais são os pontos fortes e
					os problemas apontados.
				`,
				whatToDo: `
					Revisite a experiência e a jornada do seu cliente, e entenda quais
					os pontos a empresa está mandando bem e onde está mandando mal. Para
					isso. existem muitos feedbacks que já chegam na sua operação com os
					canais que você construiu, mas provavelmente será necessário buscar
					ativamente mais clientes para descobrir as causas raiz. Este esforço
					neste momento de operação vai possibilitar você não somente entregar
					o seu produto, mas fidelizar e rentabilizar mais em cima da sua base
					de clientes atual.
				`,
			},
			{
				result: 'positive',
				yourCompanyToday: `
					De uma forma geral, você já possui muitos pontos consolidados na sua
					operação. Você entende o valor que o seu produto entrega, possui
					processos minimamente estruturados para operacionalizar essa jornada
					e tem alguns direcionamentos de como uma rotina de uma área da
					experiência do cliente precisa para rodar bem. Existem alguns
					ajustes finos para realizar e conseguir evoluir para o próximo
					nível, e o desafio atual é consolidar o que já deu certo, mapear o
					que deu errado e atacar estes pontos de melhoria para evoluir para o
					próximo nível.
				`,
				whatToDo: `
					Recomendamos fortemente que você siga aprimorando seus processos e
					aprenda novas formas de fazer essa gestão. Agora pode ser o momento
					para buscar inovação na jornada do seu cliente, o que pode trazer
					crescimento de maneira ainda mais sustentável, minimizando os custos
					de aquisição através de recompra, diminuição de churn e aumentando a
					probabilidade de indicações e recomendações.
				`,
			},
		],
	},
	{
		id: 2,
		categorie: 'Gestão de Negócios',
		diagnosticResult: [
			{
				result: 'negative',
				yourCompanyToday: `
					Quando um modelo de negócio não é bem definido e as pontas soltas da
					operação não estão mapeadas, fica extremamente complexo desenvolver
					estratégias com objetivos claros, identificar as tendências do seu
					segmento e as possíveis oportunidades. O resultado dessa falta de
					visão pode ser realmente preocupante, pois compromete a
					sustentabilidade de uma empresa em vários níveis: na gestão
					financeira, na gestão dos recursos, no mapeamento de oportunidades, no
					crescimento, na contratação de pessoas, na gestão de indicadores.
					Aprender como integrar esses elementos e fazer uma gestão de forma
					eficiente é crucial para crescimento da empresa.
				`,
				whatToDo: `
					Está na hora de voltar ao básico muito bem feito. Revisite o modelo de
					negócio da sua empresa, entenda e consolide o que é essencial ter em
					cada frente de crescimento. Avalie seu produto, seu mercado, seus
					diferenciais perante aos seus concorrentes e a sua sustentabilidade
					financeira para conseguir crescer de forma embasada. Avalie também sua
					estrutura interna, gestão da informação, estrutura de cada área e como
					cada uma está entregando valor para a empresa como um todo. Lembre-se:
					geralmente a maioria dos problemas são resolvidos com o básico bem
					feito.
					`,
			},
			{
				result: 'regular',
				yourCompanyToday: `
					Você já entendeu as implicações que uma empresa pode ter quando não
					tem um modelo de negócio claro e com uma estratégia pautada em
					indicadores sólidos. Você também já percebeu que é preciso olhar para
					frente tomando cuidado com as decisões do presente.
					Entretanto, não tem clareza suficiente acerca de quais indicadores
					considerar, que recursos deve priorizar ou como identificar
					oportunidades a partir das tendências de mercado.
				`,
				whatToDo: `
					Entender como fazer a melhor gestão do seu negócio ao mesmo tempo que
					se mantém atento aos movimentos do mercado. Você já saiu do zero e tem
					uma estrutura mínima para conseguir rodar e entregar valor para os
					seus clientes. O momento é de refinar as principais alavancas de cada
					área do seu negócio e melhorar eficiência operacional. Isso passa por
					revisitar processos, alocação de recursos financeiros e humanos, e
					construir estruturas de acompanhamento.
				`,
			},
			{
				result: 'positive',
				yourCompanyToday: `
					Você tem consciência da dimensão estratégica que uma boa gestão do
					negócio pode trazer para sua empresa. Entretanto, você já parou para
					se perguntar como alavancar seu negócio e ter mais poder competitivo a
					partir dessa boa gestão? Suas equipes estão efetivamente alinhadas aos
					objetivos? Como diria Nassim Taleb em 'A lógica do cisne negro', "as
					pessoas são muito boas em enganarem-se a si mesmas pensando que sabem
					muito mais do que realmente sabem, o que torna fácil que eventos
					grandes e incomuns as peguem de surpresa".

					Quando você tem a base bem construída ou minimamente direcionada, é
					hora de acertar os últimos ajustes e se preparar para inovar ainda
					mais. Estar a frente de concorrentes, construir diferenciais
					competitivos de longo prazo. Inovar em produtos, processos,
					ferramentas, pessoas e gestão do negócio.
				`,
				whatToDo: `
					Tudo que foi feito até então para a empresa chegar no patamar que ela
					está hoje não habilita necessariamente a empresa para chegar no
					próximo patamar. Ou seja, para continuar crescendo e inovando em
					gestão, é preciso sempre se reciclar. Em ideias, em modelo de gestão,
					em processos, em práticas do dia a dia. Parabéns pelo que você
					construir até agora, mas a empresa precisa ter humildade o suficiente
					para ser uma eterna aprendiz e continuar aprendendo para crescer ainda
					mais. Dica extra: busque referências não somente no seu país, mas no
					mundo inteiro.
				`,
			},
		],
	},
	{
		id: 3,
		categorie: 'Marketing e Growth',
		diagnosticResult: [
			{
				result: 'negative',
				yourCompanyToday: `
					É urgente para você ter um entendimento claro sobre como o poder de sua
					marca pode contribuir para o crescimento do negócio. Tenha em mente que
					ações podem determinar a continuidade do seu negócio, como desenvolver
					estratégias que envolvam líderes e liderados, ser coerente com a
					comunicação de sua empresa, ter eficiência na captação de leads e
					desenvolver ações que posicionem positivamente sua marca, por exemplo.
					Não olhar para essas questões pode comprometer a reputação e a
					capacidade de crescimento de sua empresa no mercado: há o risco de você
					esgotar os canais de aquisição e comprometer o futuro da sua empresa.
				`,
				whatToDo: `
					No mercado existem posicionamentos muito bem definidos de cada um dos
					players atuantes. A sua marca está neste meio, e o cliente já possui uma
					percepção da sua marca e do seu produto bem como a percepção dos seus
					concorrentes. É fundamental você começar a entender o posicionamento da
					sua marca do ponto de vista do cliente. Ouvir os seus clientes e analisar
					seus concorrentes vai te dar consciência do seu status atual. Com isso em
					mãos, você vai conseguir começar ou recomeçar a sua estratégia de
					marketing e desenhar formas de crescimento condizentes com o seu produto
					e o seu planejamento estratégico.
				`,
			},
			{
				result: 'regular',
				yourCompanyToday: `
					Pelo visto, você já percebeu a importância de desenvolver estratégias
					de marketing para posicionar sua empresa e criar condições de
					crescimento, desenvolvendo e aprimorando as alavancas necessárias
					para evoluir.

					Entretanto, diante de um mercado altamente competitivo, quanto você
					tem domínio e conhecimento do potencial do seu negócio? Quais métodos
					você utiliza para captar e adquirir clientes? Como você tem se
					posicionado no mercado, de forma passiva ou assumindo protagonismo?
					A empresa busca captar novos clientes em novos canais?
				`,
				whatToDo: `
					É necessário que você aprimore e atualize suas práticas de Marketing
					e Growth. Com uma estrutura mínima, o seu foco deve ser em consolidar
					o conhecimento que você tem do mercado, dos seus concorrentes, dos seus
					diferenciais competitivos e de como os seus processos da área rodam,
					e agir! Eficiência operacional é a palavra chave para quem está nessa
					fase. É hora de preparar a máquina de aquisição e posicionamento para
					o próximo nível!
				`,
			},
			{
				result: 'positive',
				yourCompanyToday: `
					Para você é muito claro que Marketing e Growth caminham juntos para
					atingir as metas de negócio. Possui bons alinhamentos e consciência de
					como a marca e as alavancas de crescimento é um dos principais
					diferenciais competitivos para empresas de alto crescimento. Existem
					alguns ajustes para fazer na operação e no seu planejamento, mas de
					forma geral você já está com uma boa estrutura.
				`,
				whatToDo: `
					Toda operação tem algo para melhorar. Você precisa encontrar quais são
					os ajustes a serem feitos agora em processos, pessoas e produto.
					Na medida em que você aperta os últimos parafusos, construa uma
					estrutura para inovar. Busque referências das empresas que mais inovam
					no mundo no seu setor e como elas fazem para posicionar muito bem a
					marca e construir novas vias de crescimento. Uma coisa é certeza: isso
					passa diretamente por ouvir ainda mais o seu cliente e solucionar os
					atuais problemas e os problemas que eles terão no futuro.
				`,
			},
		],
	},
	{
		id: 4,
		categorie: 'Vendas',
		diagnosticResult: [
			{
				result: 'negative',
				yourCompanyToday: `
					Quando os processos de sua área comercial não estão definidos de forma
					clara, seu poder competitivo pode ficar extremamente fragilizado,
					podendo até mesmo comprometer a sustentabilidade do seu negócio,
					gerando baixa previsibilidade, perda de oportunidades que poderiam
					aumentar ganho de receita, números fracos de vendas,
					baixo crescimento ou estagnação do negócio.

					Aprender a estruturar uma equipe de alto nível, desenvolver
					indicadores efetivos, ter metas claras e objetivas pode elevar
					a energia do seu time comercial e levar a empresa para outro nível.
				`,
				whatToDo: `
					Comece entendendo qual o seu ponto mais fraco da operação. É geração
					ou qualificação de leads? São processos comerciais ou treinamento da
					equipe comercial? É previsibilidade de receita ou acompanhamento de
					indicadores? Escolha uma área e ataque ela juntamente com o time.
					Você vai resolver todos os problemas com o tempo, mas precisamos
					começar pelo maior e mais prejudicial gargalo da sua operação. E a
					identificação deste gargalo depende da sua imersão na área de
					aquisição e vendas.
				`,
			},
			{
				result: 'regular',
				yourCompanyToday: `
					Você sabe a importância da previsibilidade das vendas no seu negócio,
					como é fundamental uma equipe bem estruturada e o cumprimento de
					rituais e rotinas para atingir os objetivos.
					Todavia, muitas vezes, é recorrente o foco somente na urgência de
					bater metas, o que compromete as estratégias que envolvem o pós-venda,
					a construção de times resilientes e a sensibilização de toda a empresa
					com a área comercial.
				`,
				whatToDo: `
					Este é o momento de identificar os pontos fortes e tudo que a empresa
					já acertou, e identificar os pontos que precisam ser melhorados. Para
					corrigir, é preciso criar primeiramente consciência. Para isso, é
					preciso convidar o time para realizar uma retrospectiva de tudo que
					aconteceu até o momento. Existem informações que chegam facilmente a
					gestão, outras ficam nas entrelinhas e atrapalham a visualização do
					todo e os seus resultados. No zero sua operação não está mais, mas
					para crescer é preciso aprofundamento de análises.
				`,
			},
			{
				result: 'positive',
				yourCompanyToday: `
					Você tem plena consciência do quão fundamental para o seu negócio é
					ter uma área comercial bem estruturada, com uma equipe forte e
					indicadores claros. Contudo, como você consegue alavancar ainda mais
					seus resultados e evitar o risco da estagnação? Você já tem uma
					estrutura que funciona, por mais que precise de alguns ajustes.
					O momento da empresa é consolidar o que deu certo, colocar na mesa
					o que precisa melhorar e buscar boas referências para evoluir para
					o próximo patamar da área.
				`,
				whatToDo: `
					Apesar do bom nível de maturidade, pode ser crucial para seu negócio
					aprender mais sobre como aumentar receita, como encontrar avenidas e
					canais de crescimento, como dirigir investimentos para outras áreas
					que podem impulsionar seu time comercial e como encontrar novas
					oportunidades de negócios. É hora de aumentar eficiência operacional
					e inovar saindo do óbvio. Busque referências nacionais e internacionais
					para o seu negócio e comece a fazer o 120% necessário para aumentar
					ainda mais sua receita. A pergunta central é: se você fatura x, o que
					falta pra você faturar no próximo ano 3x? Tem espaço pra isso?
					O que é preciso ser feito? É a operação que fará isso ou vou precisar
					uma nova ótica estratégica pro negócio?
				`,
			},
		],
	},
	{
		id: 5,
		categorie: 'Cultura e Liderança',
		diagnosticResult: [
			{
				result: 'negative',
				yourCompanyToday: `
					A cultura organizacional é a principal riqueza de uma empresa. Ela
					dita o dia a dia da companhia, como as relações se estabelecem e como
					as principais decisões são tomadas. Junto com a cultura, a definição
					do que é a missão da empresa e a visão que ela quer atingir rege o
					motivo de uma empresa existir. Pode ser que alguns destes pilares
					estejam vagos na sua empresa, e isso está afetando a forma como vocês
					tocam a operação no dia a dia. No longo prazo, isso divide as empresas
					que sobrevivem e as que vão dominar o mercado.
				`,
				whatToDo: `
					Revisite os conceitos básicos de cultura, missão, visão, valores e a
					forma como você está construindo o dia a dia e a contratação das
					pessoas que refletem estes valores. Garantir que não haja confusão
					nestes pilares da operação até a estratégia vai garantir que você
					tenha pilares sólidos de crescimento. Não adianta pensar em vendas e
					todas as outras áreas se este pilar não está consolidado.
				`,
			},
			{
				result: 'regular',
				yourCompanyToday: `
					Você sabe a importância que uma gestão de Cultura e Liderança tem para
					o crescimento do seu negócio. Entretanto, como você vem desenvolvendo
					a cultura em sua empresa? Você está seguro quanto às melhores práticas
					para envolver seus colaboradores e suas lideranças? Existem subculturas
					que podem estar afetando seu negócio? Se sim, como detectar e como
					promover um ambiente onde todos estejam "na mesma página"? Você já
					possui algo estruturado em funcionamento. O momento agora é de
					entender o que está dando certo e o que precisa de revisão.
				`,
				whatToDo: `
					Busque entender a lacuna que existe entre o que a empresa possui no
					papel e na parede, e o que de fato acontece na operação. Com certeza
					já existem muitas coisas que dão certo, mas a sustentação do seu
					crescimento virá daquilo que você precisa diagnosticar e corrigir.
					Para isso, não tem outro jeito: converse com o time, mergulhe nos
					rituais e nas rotinas de tomada de decisão, entenda o processo de
					recrutamento e seleção de pessoas e garanta que todos os valores
					importantes no dia a dia da companhia estejam sendo aplicados de fato.
				`,
			},
			{
				result: 'positive',
				yourCompanyToday: `
					Seu entendimento sobre Cultura e Liderança é elevado e você tem plena
					consciência dos diferenciais de negócio que são possíveis a partir
					dessa gestão.

					Entretanto, você já se perguntou como alavancar ainda mais essa boa
					cultura? Como está a cultura de inovação nas áreas e o nível de
					engajamento entre as equipes? Como refinar os níveis de satisfação de
					seus clientes internos? Como alinhar os resultados com as equipes?
					Você possui uma boa estrutura construída, que funciona e já trouxe
					resultados. Quais são as áreas que, se melhoradas, vai oferecer toda
					a sustentação para a empresa ir para o próximo nível?
				`,
				whatToDo: `
					Entenda muito bem os pontos fortes da sua operação quanto a cultura
					e quem são os líderes que exalam e personificam a sua cultura. Estas
					pessoas precisam ser exemplos e missionários da forma com que a
					empresa trabalha. Reforçar e recompensar comportamentos corretos, e
					exemplificar e corrigir pontos de melhoria é o que vai consolidar
					sua cultura e liderança como ponto forte. Como próximo passo, eleva
					a barra! Busque referências nacionais e internacionais na sua área
					para inovar e ir para o próximo nível.
				`,
			},
		],
	},
];
