export const formatCurrency = (value, decimals = 2, currency = 'R$') => {
	var x = value.replace(/\D/g, '');
	const preValue = !x ? 0 : x;
	var formatter = new Intl.NumberFormat('pt-BR', {
		style: 'currency',
		currency: 'BRL'
	});
	
	return formatter.format(parseInt(preValue)/100);
};

export const phoneMask = (e) => {
	var x = e.target.value
		.replace(/\D/g, '')
		.match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
	e.target.value = !x[2]
		? x[1]
		: '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
	return e.target.value;
};
