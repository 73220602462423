import { Grid, useMediaQuery } from '@material-ui/core';
import React from 'react';
import useMediaQueries from '~/SkillsAssessment/hooks/useMediaQueries';
import { ReactComponent as DownloadIcon } from '../../../assets/downloadIcon.svg';
import { ReactComponent as ShareIcon } from '../../../assets/shareIcon.svg';
import { IconButton, Text, Title } from './HeaderResult.style';

const HeaderResult = ({
	shareOnClick,
	downloadOnClick,
	isRelative = false,
}) => {
	const { isBetweenXsAndSm: isMobile } = useMediaQueries();
	const isSmallerThanXS = useMediaQuery('(max-width:360px)');
	React.useEffect(() => {
		// console.log('isSmallerThanXS', isSmallerThanXS);
	}, [isSmallerThanXS]);

	return (
		<Grid
			container
			style={{
				backgroundColor: 'rgba(0, 0, 0, 0.25)',
				padding: isMobile ? '0px 16px' : '0px 53px',
				width: '100%',
				height: '56px',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between',
				flexDirection: 'row',
				position: isRelative ? 'relative' : 'fixed',
				top: 0,
				left: 0,
				zIndex: 2,
				backdropFilter: 'blur(2px)',
			}}
		>
			<Grid item>
				<Title style={{ fontSize: isMobile ? '16px' : '20px' }}>
					{isSmallerThanXS ? 'Diagnóstico' : 'Resultado do Diagnóstico'}
				</Title>
			</Grid>
			<Grid item style={{ display: 'flex', alignItems: 'center' }}>
				<IconButton
					disableRipple
					onClick={shareOnClick}
					style={{
						marginRight: '8px !important',
						padding: isMobile ? '0px !important' : '8px !important',
					}}
				>
					<ShareIcon />
					{!isMobile ? <Text>Compartilhar</Text> : null}
				</IconButton>
				<IconButton
					disableRipple
					onClick={downloadOnClick}
					style={{
						padding: isMobile ? '0px !important' : '8px !important',
					}}
				>
					<DownloadIcon />
				</IconButton>
			</Grid>
		</Grid>
	);
};

export default HeaderResult;
