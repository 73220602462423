import { CardMedia, Grid } from '@material-ui/core';
import React from 'react';
import LogoG4BrancaSvg from '../../../assets/logo-g4-branca.svg';

const Footer = () => {
	React.useEffect(() => {}, []);

	return (
		<Grid
			container
			style={{
				backgroundColor: 'rgba(0, 0, 0, 0.1)',
				width: '100%',
				height: '200px',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				flexDirection: 'column',
			}}
		>
			<CardMedia
				component="img"
				alt="Logo G4"
				image={LogoG4BrancaSvg}
				style={{
					width: 'auto',
					height: 'auto',
					marginBottom: '25px',
				}}
			/>
			2023 - Gestão 4.0 I Powered By G4 SKILLS
		</Grid>
	);
};

export default Footer;
