import { createGlobalStyle } from 'styled-components';
import colors from './colors.style';
import background from '../assets/background2.png';
const width = window.innerWidth;

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Montserrat:400,700|Roboto');

  * {
    padding: 0;
    outline: 0;
    box-sizing: border-box;
		font-family: 'Montserrat', sans-serif !important;
  }

  html, body, #root {
    min-height: 100%;
		max-height: 100%;
		-webkit-background-size: contain;
     -moz-background-size: contain;
       -o-background-size: contain;
          background-size: contain;
		min-height:100vh;
		margin: 0;
		padding:0;
		overflow-x: hidden;
  }

  body {
    background: ${colors.skillsBackground};
		background-repeat: no-repeat;
  	background-size: cover;
		width: 100%;
		height: 100%;
    -webkit-font-smothing: antialiased !important;
  }

  body, button {
    color: #fff;
		border-color: #fff;
    font-size: 14px;
  }

	h1 {
		color: #fff;
		font-weight: unset;
		font-size: 48px;
		line-height: 32px;
	}

	button {
		/* position: absolute; */
		width: 262.51px;
		top: 20%;
		bottom: 21.98%;
		font-family: Montserrat;
		font-style: normal;
		font-weight: bold;
		font-size: 18.8928px;
		line-height: 23px;
		text-align: right;
		letter-spacing: 1.34949px;
		color: #FFFFFF !important;
		text-shadow: 0px 3.36721px 3.36721px rgba(0, 0, 0, 0.25);
		background: linear-gradient(174.66deg, rgba(233, 115, 78, 0.5) -0.95%, rgba(240, 77, 87, 0) 99.53%), ${
			colors.primary
		} !important;
		box-shadow: 0px 21.8652px 41.1773px rgba(240, 77, 87, 0.15), 0px -21.6181px 41.1773px rgba(233, 115, 78, 0.15);
		border-radius: 33.3742px !important;
		cursor: pointer;
		&:hover {
			background: ${colors.primaryDark} !important;
		}
		&:disabled {
			color: ${colors.gray} !important;
			background: ${colors.primaryDark} !important;
		}
  }

	img {
		margin: 10px
	}

	span {
		font-size: ${width < 768 ? '12px' : '14px'} !important;
		margin: ${width < 768 ? '1px' : '6px'}
	}
`;
