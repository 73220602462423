import { CardMedia, Grid, Typography } from '@material-ui/core';
import React from 'react';
import useMediaQueries from '~/SkillsAssessment/hooks/useMediaQueries';
import { ReactComponent as HandShakeIcon } from '../../../assets/handShakeIcon.svg';
import LogoG4HorizontalSvg from '../../../assets/logo-g4educacao-horizontal.svg';

const HeaderAssessment = ({ isGuidance = false }) => {
	const { isBetweenXsAndSm: isMobile } = useMediaQueries();
	React.useEffect(() => {}, []);

	if (!isGuidance) {
		return (
			<Grid
				container
				style={{
					padding: '0px 53px',
					width: '100%',
					height: isMobile ? '50px' : '80px',
					display: 'flex',
					alignItems: 'center',
					justifyContent: isMobile ? 'center' : 'flex-start',
				}}
			>
				<CardMedia
					component="img"
					alt="logo G4 Educação"
					image={LogoG4HorizontalSvg}
					style={{
						width: 'auto',
						height: 'auto',
					}}
				/>
			</Grid>
		);
	}

	return (
		<Grid
			container
			style={{
				padding: isMobile ? '0px 16px' : '0px 53px',
				width: '100vw',
				height: isMobile ? '50px' : '80px',
				flexDirection: isMobile ? 'row-reverse' : 'row',
			}}
		>
			<Grid
				item
				xs={6}
				width="100%"
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: isMobile ? 'flex-end' : 'flex-start',
				}}
			>
				{!isMobile ? <HandShakeIcon style={{ marginRight: '16px' }} /> : null}
				<Typography
					style={{
						fontStyle: 'normal',
						fontWeight: 600,
						fontSize: isMobile ? '14px' : '16px',
						lineHeight: '150%',
						letterSpacing: '-0.005em',
						color: 'rgba(255, 255, 255, 0.85)',
						whiteSpace: 'nowrap',
					}}
				>
					Dicas do diagnóstico
				</Typography>
			</Grid>
			<Grid
				item
				xs={6}
				width="100%"
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: isMobile ? 'flex-start' : 'flex-end',
				}}
			>
				<CardMedia
					component="img"
					alt="logo G4 Educação"
					image={LogoG4HorizontalSvg}
					style={{
						width: '120px',
						height: '16px',
					}}
				/>
			</Grid>
		</Grid>
	);
};

export default HeaderAssessment;
