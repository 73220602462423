import styled from 'styled-components';
import colors from '../../styles/colors.style';

const Title = styled.h1`
	text-align: center;
	font-style: normal;
	font-weight: bold;
	font-size: 26px;
	line-height: 100%;
	color: ${colors.skillsText};
	margin-top: 10%;
	margin-bottom: 5%;
`;

const Text = styled.h1`
	text-align: left;
	color: ${colors.skillsText};
	font-weight: unset;
	font-size: 14px;
	line-height: 130%;
	text-align: justify;
`;

export { Title, Text };
