import styled from 'styled-components';
import colors from '../../styles/colors.style';
import fonts from '../../styles/fonts.style';
import { device } from '../../styles/devices.style';

const Title = styled.h2`
	text-align: left;
	font-weight: bold;
	font-size: ${fonts.large};
	color: ${colors.skillsText};
`;

const Text = styled.span`
	color: ${colors.skillsText};
	font-weight: unset;
	font-size: ${fonts.small};
`;

const SubTitle = styled.span`
	text-align: left;
	font-size: ${fonts.small};
	color: ${colors.skillsText};
`;

const Img = styled.img`
	min-width: 340px;
	max-width: 340px;
	@media ${device.mobileL} {
		min-width: 240px;
		max-width: 240px;
	}
`;

const Container = styled.div`
	text-align: left;
	color: ${colors.skillsText};
	font-size: ${fonts.small};
	margin-top: 10px;
	margin-bottom: 10px;
`;

const ContainerCTA = styled.div`
	text-align: left;
	color: ${colors.skillsText};
	font-size: ${fonts.small};
	margin-top: 30px;
	margin-bottom: 30px;
	max-width: 100%;
`;

const LeadItem = styled.b`
	text-align: left;
	color: ${colors.primary};
	font-size: ${fonts.regular};
`;

const Title2 = styled.b`
	text-align: left;
	font-weight: bold;
	font-size: ${fonts.normal};
	color: ${colors.primary};
	text-align: center;
`;

export {
	Title,
	SubTitle,
	Text,
	Img,
	Container,
	ContainerCTA,
	LeadItem,
	Title2,
};
