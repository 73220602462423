import { useMediaQuery, useTheme } from '@material-ui/core';
import { useDebugValue } from 'react';

const useMediaQueries = () => {
	const theme = useTheme();
	const extraSmallScreen = useMediaQuery(theme.breakpoints.up('xs'));
	const smallScreen = useMediaQuery(theme.breakpoints.up('sm'));
	const mediumScreen = useMediaQuery(theme.breakpoints.up('md'));
	const largeScreen = useMediaQuery(theme.breakpoints.up('lg'));

	const extraLargeScreen = useMediaQuery(theme.breakpoints.up('xl'));
	const isBetweenXsAndSm = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
	const isBetweenSmAndMd = useMediaQuery(theme.breakpoints.between('sm', 'md'));
	const isBetweenMdAndLg = useMediaQuery(theme.breakpoints.between('md', 'lg'));
	const isBetweenLgAndXl = useMediaQuery(theme.breakpoints.between('lg', 'xl'));

	useDebugValue({
		extraSmallScreen: { extraSmallScreen, value: 'min-width: 0px' },
		smallScreen: { smallScreen, value: 'min-width: 600px' },
		mediumScreen: { mediumScreen, value: 'min-width: 900px' },
		largeScreen: { largeScreen, value: 'min-width: 1200px' },
		extraLargeScreen: { extraLargeScreen, value: 'min-width: 1536px' },
		isBetweenXsAndSm: { isBetweenXsAndSm, value: 'between 0px and 600px' },
		isBetweenMdAndLg: { isBetweenMdAndLg, value: 'between 900px and 1200px' },
		isBetweenLgAndXl: { isBetweenLgAndXl, value: 'between 1200px and 1536px' },
	});

	return {
		extraSmallScreen,
		smallScreen,
		mediumScreen,
		largeScreen,
		extraLargeScreen,
		isBetweenXsAndSm,
		isBetweenSmAndMd,
		isBetweenMdAndLg,
		isBetweenLgAndXl,
	};
};
export default useMediaQueries;
