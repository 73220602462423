import { Box, CardMedia, Container, Grid } from '@material-ui/core';
import LensIcon from '@material-ui/icons/Lens';
import React from 'react';
import { useHistory } from 'react-router-dom';
import {
	logViewPageGuidanceSkillsAssessment,
	logViewPageStartSkillsAssessment,
} from '~/SkillsAssessment/events';
import useMediaQueries from '~/SkillsAssessment/hooks/useMediaQueries';
import DotIcon from '../../../assets/dotIcon.svg';
import { ReactComponent as HourGlassIcon } from '../../../assets/hourGlassIcon.svg';
import { ReactComponent as LeaderHeadIcon } from '../../../assets/leaderHeadIcon.svg';
import { ReactComponent as TeamsIcon } from '../../../assets/teamsIcon.svg';
import ButtonAssessmentNavigator from '../../components/ButtonAssessmentNavigator';
import ContainerWrapper from '../../components/ContainerWrapper';
import HeaderAssessment from '../../components/HeaderAssessment';
import * as route from '../../routes';
import { SubTitle, Title } from './Guidance.style';

const Guidance = () => {
	const { isBetweenXsAndSm: isMobile } = useMediaQueries();
	const [activeStep, setActiveStep] = React.useState(0);
	const history = useHistory();

	const goToPreviuosPage = () => {
		if (activeStep === 0) {
			history.push({ pathname: route.ROUTE_SKILLS_ASSESSMENT_INFORMATION });
		} else {
			setActiveStep((prevActiveStep) => prevActiveStep - 1);
		}
	};

	const goToNextPage = () => {
		if (activeStep === 2) {
			history.push({
				pathname: route.ROUTE_SKILLS_ASSESSMENT_START,
			});
			setActiveStep(0);
			logViewPageStartSkillsAssessment();
		} else {
			setActiveStep((prevActiveStep) => prevActiveStep + 1);
			logViewPageGuidanceSkillsAssessment({ step: activeStep + 2 });
		}
	};

	const dataText = [
		{
			id: 1,
			icon: (
				<HourGlassIcon
					style={{ color: '#FF6559', width: 'auto', height: 'auto' }}
				/>
			),
			title: 'Reserve um tempo sem distrações',
			subTitle: (
				<SubTitle
					style={{ fontSize: isMobile ? '14px !important' : '16px !important' }}
				>
					{isMobile ? (
						<>
							O Diagnóstico demora entre 10 a 15 minutos para ser respondido.
							Faça-o de uma só vez, sem interrupções.
						</>
					) : (
						<>
							O Diagnóstico demora entre 10 a 15 minutos para ser respondido.{' '}
							<br />
							Faça-o de uma só vez, sem interrupções.
						</>
					)}
				</SubTitle>
			),
		},
		{
			id: 2,
			icon: (
				<LeaderHeadIcon
					style={{ color: '#FF6559', width: 'auto', height: 'auto' }}
				/>
			),
			title: 'Não tenha pressa',
			subTitle: (
				<SubTitle
					style={{ fontSize: isMobile ? '14px !important' : '16px !important' }}
				>
					{isMobile ? (
						<>
							Reflita com qualidade para responder cada pergunta. A reflexão é
							um ponto fundamental do processo.
						</>
					) : (
						<>
							Reflita com qualidade para responder cada pergunta. <br /> A
							reflexão é um ponto fundamental do processo.
						</>
					)}
				</SubTitle>
			),
		},
		{
			id: 3,
			icon: (
				<TeamsIcon
					style={{ color: '#FF6559', width: 'auto', height: 'auto' }}
				/>
			),
			title: 'Consulte o seu time',
			subTitle: (
				<SubTitle
					style={{ fontSize: isMobile ? '14px !important' : '16px !important' }}
				>
					{isMobile ? (
						<>
							Levante a mão quando necessário. Peça ajuda, se for necessário.
							Você pode consultar sócios e líderes de áreas da sua empresa para
							responder de forma mais assertiva.
						</>
					) : (
						<>
							Levante a mão quando necessário. Peça ajuda, se for necessário.{' '}
							<br />
							Você pode consultar sócios e líderes de áreas da sua empresa{' '}
							<br />
							para responder de forma mais assertiva.
						</>
					)}
				</SubTitle>
			),
		},
	];

	return (
		<ContainerWrapper noBackgroundImage={isMobile}>
			<HeaderAssessment isGuidance />
			<Container maxWidth="lg" disableGutters>
				<Grid container style={{ padding: '16px' }}>
					<Grid
						item
						xs={12}
						style={{
							display: 'flex',
							alignItems: 'center',
							flexDirection: 'column',
							justifyContent: 'center',
						}}
					>
						<Box
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								marginBottom: '25px',
							}}
						>
							{dataText.map((_, index) => (
								<>
									{activeStep === index ? (
										<CardMedia
											component="img"
											alt="banner"
											image={DotIcon}
											onClick={() => setActiveStep(index)}
											style={{
												color: '#ff6559',
												margin: '0px 6px',
												cursor: 'pointer',
											}}
										/>
									) : (
										<LensIcon
											onClick={() => setActiveStep(index)}
											style={{
												margin: '0px 6px',
												color: '#fff',
												width: '12px',
												height: '12px',
												cursor: 'pointer',
											}}
										/>
									)}
								</>
							))}
						</Box>
						<Box
							style={{
								width: isMobile ? '326px' : '390px',
								height: '210px',
								background: '#1E2B5F',
								borderRadius: '100px',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							{dataText[activeStep].icon}
						</Box>
						<Title style={{ fontSize: isMobile ? '18px' : '24px' }}>
							{dataText[activeStep].title}
						</Title>
						{dataText[activeStep].subTitle}
					</Grid>
				</Grid>
			</Container>
			{isMobile ? <Grid style={{ height: '150px' }} /> : null}
			<ButtonAssessmentNavigator
				nextButtonText="Entendi"
				disabledNextIcon
				{...{ goToPreviuosPage, goToNextPage }}
			/>
		</ContainerWrapper>
	);
};

export default Guidance;
