import {
	CircularProgress,
	DialogContent,
	DialogTitle as DialogTitleMUI,
} from '@material-ui/core';
import React from 'react';
import useMediaQueries from '~/SkillsAssessment/hooks/useMediaQueries';
import { Dialog, SubTitle } from './ExportPDFDialog.style';

function DialogTitle(props) {
	React.useEffect(() => {}, []);
	const { children, onClose, ...other } = props;

	return (
		<DialogTitleMUI
			style={{
				margin: 0,
				padding: '12px 24px',
				background: '#0F1445',
				color: '#FFFFFF',
			}}
			{...other}
		>
			{children}
		</DialogTitleMUI>
	);
}

const ExportPDFDialog = ({ isExportPdfLoading }) => {
	const { isBetweenXsAndSm: isMobile } = useMediaQueries();
	React.useEffect(() => {}, []);
	return (
		<Dialog
			open={isExportPdfLoading}
			PaperProps={{
				style: {
					borderRadius: '10px',
					margin: 24,
					minWidth: isMobile ? 'auto' : '450px',
				},
			}}
		>
			<DialogTitle>Exportando...</DialogTitle>
			<DialogContent
				dividers
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
				}}
			>
				<SubTitle>Estamos gerando seu PDF, aguarde um momento...</SubTitle>
				<CircularProgress style={{ margin: '16px 0px' }} />
			</DialogContent>
		</Dialog>
	);
};

export default ExportPDFDialog;
