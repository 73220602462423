import React from 'react';
import { ButtonStyled } from './Button.style';

const Button = ({ children, ...props }) => {
	React.useEffect(() => {}, []);

	return (
		<ButtonStyled disableRipple {...props}>
			{children}
		</ButtonStyled>
	);
};

export default Button;
