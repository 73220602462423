import styled from 'styled-components';
import margins from '../../styles/margins.style';
import colors from '../../styles/colors.style';
import fonts from '../../styles/fonts.style';
import background from '../../assets/1920.png';
import backgroundMobile from '../../assets/mobile.png';
import { device } from '../../styles/devices.style';
const width = window.innerWidth;

const Body = styled.body`
	display: flex;
	flex-direction: column;
	justify-content: center;
	color: ${colors.skillsText};
	line-height: 25.4;
	width: 100%;
	height: 100%;
`;

const Title = styled.h1`
	font-style: normal;
	font-weight: 700;
	font-size: ${fonts.biggest};
	line-height: 100%;
	color: ${colors.skillsText};
	margin-top: ${margins.small};
	margin-bottom: ${margins.small};
	@media ${device.mobileL} {
		font-weight: 700;
		font-size: ${fonts.big};
	}
`;

const Text = styled.p`
	text-align: left;
	color: ${colors.skillsText};
	font-weight: unset;
	font-size: ${fonts.normal};
	line-height: 135%;
	@media ${device.mobileL} {
		font-size: ${fonts.normal};
	}
`;

const Img = styled.img`
	margin: auto;
	max-width: 100%;
	max-height: 100%;
	@media ${device.mobileS} {
		max-width: 45% !important;
	}
`;

const Link = styled.a`
	color: ${colors.skillsLink};
	&:hover {
		cursor: pointer;
		text-decoration: underline;
	}
`;

export { Body, Title, Text, Img, Link };
