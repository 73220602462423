import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import React from 'react';
import ReactDOM from 'react-dom';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { SkillsAssessmentContextProvider } from './SkillsAssessment/context/SkillsAssessmentContext/SkillsAssessmentContext';
import colors from './styles/colors.style';

const theme = createMuiTheme({
	palette: {
		common: { black: colors.dark, white: colors.white },
		primary: { main: colors.primary, dark: colors.dark, light: colors.light },
		text: { primary: colors.primary, secondary: colors.secondary },
	},
});

ReactDOM.render(
	<React.StrictMode>
		<MuiThemeProvider theme={theme}>
			<SkillsAssessmentContextProvider>
				<NotificationContainer />
				<App />
			</SkillsAssessmentContextProvider>
		</MuiThemeProvider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
