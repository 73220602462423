import React from 'react';
import Chart from 'react-apexcharts';

const SemiCircleChart = ({ height = '160px', series = [0] }) => {
	React.useEffect(() => {}, []);

	const options = {
		stroke: {
			show: true,
			curve: 'smooth',
			lineCap: 'butt',
			colors: undefined,
			width: 2,
			dashArray: 0,
		},
		dataLabels: {
			enabled: false,
		},
		chart: {
			id: 'radialBar',
			offsetY: -20,
			sparkline: {
				enabled: true,
			},
			toolbar: { show: false },
		},
		colors: ['#FF6559', '#FB9991'],
		plotOptions: {
			radialBar: {
				startAngle: -90,
				endAngle: 90,
				track: {
					background: 'rgba(255, 255, 255, 0.1)',
					strokeWidth: '97%',
					margin: 5,
				},
				dataLabels: {
					name: {
						show: false,
					},
					value: {
						offsetY: -2,
						fontSize: '16px',
						color: '#fff',
						formatter: function (val) {
							return val + '/100';
						},
					},
				},
			},
		},
		grid: {
			padding: {
				top: -10,
			},
		},
	};

	return (
		<Chart
			options={options}
			series={series}
			type="radialBar"
			height={height}
			width="100%"
		/>
	);
};

export default SemiCircleChart;
