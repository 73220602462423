import {
	Dialog as DialogMUI,
	IconButton as IconButtonMUI,
	Snackbar as SnackbarMUI,
} from '@material-ui/core';
import {
	EmailShareButton as EmailShareButtonDEFAULT,
	LinkedinShareButton as LinkedinShareButtonDEFAULT,
	WhatsappShareButton as WhatsappShareButtonDEFAULT,
} from 'react-share';
import styled from 'styled-components';

const Dialog = styled(DialogMUI)`
	.MuiDialogContent-root {
		background: #0f1445;
		color: rgba(255, 255, 255, 0.85);
		overflow-y: scroll !important;
		&::-webkit-scrollbar {
			width: 6px;
			border-radius: 9999;
			cursor: 'pointer';
		}
		&::-webkit-scrollbar-track {
			background-color: transparent;
		}
		&::-webkit-scrollbar-thumb {
			background-color: #cccccc;
		}
	}
`;

const IconButton = styled(IconButtonMUI)`
	width: 40px;
	height: 40px;
	padding: 0px !important;
	background: transparent !important;
	box-shadow: none !important;
	&:hover {
		background: rgba(255, 255, 255, 0.1) !important;
	}
`;

const Title = styled.h5`
	width: 100%;
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 110%;
	letter-spacing: -0.015em;
	color: #ffffff;
	text-align: center;
	margin: 8px 0 60px 0;
`;

const SubTitle = styled.p`
	width: 100%;
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 130%;
	letter-spacing: -0.015em;
	color: #ffffff;
	text-align: left;
	margin: 18px 0px;
`;

const Snackbar = styled(SnackbarMUI)`
	.MuiSnackbarContent-root {
		background: #408140 !important;
	}
`;

const EmailShareButton = styled(EmailShareButtonDEFAULT)`
	width: 64px;
	height: 64px;
	background: transparent !important;
	text-shadow: none;
	box-shadow: none;
	text-transform: none !important;

	&:hover {
		background: transparent !important;
	}
`;

const WhatsappShareButton = styled(WhatsappShareButtonDEFAULT)`
	width: 64px;
	height: 64px;
	background: transparent !important;
	text-shadow: none;
	box-shadow: none;
	text-transform: none !important;

	&:hover {
		background: transparent !important;
	}
`;

const LinkedinShareButton = styled(LinkedinShareButtonDEFAULT)`
	width: 64px;
	height: 64px;
	background: transparent !important;
	text-shadow: none;
	box-shadow: none;
	text-transform: none !important;

	&:hover {
		background: transparent !important;
	}
`;

export {
	Dialog,
	IconButton,
	Title,
	SubTitle,
	Snackbar,
	EmailShareButton,
	WhatsappShareButton,
	LinkedinShareButton,
};
