import { InputLabel as InputLabelMUI, TextField } from '@material-ui/core';
import styled from 'styled-components';

const ContainerExportPDF = styled.div`
	background: ${(props) => (props.isLoading ? 'inherit' : 'transparent')};
	width: 1000px;
`;

const Title = styled.p`
	width: 100%;
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 500;
	line-height: 130%;
	color: #ffffff;
	text-align: left;
	margin: 0px !important;
`;

const SubTitle = styled.p`
	width: 100%;
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 130%;
	color: #ffffff;
	text-align: left;
	margin: 0px;
`;

const Input = styled(TextField)`
	background: rgba(255, 255, 255, 0.05);
	// border: 1px solid rgba(255, 255, 255, 0.05);
	border-radius: 4px;
	margin-bottom: 10px;
	width: 100%;
	font: normal normal normal 11px/140% Montserrat !important;
	font-size: 11px !important;
	font-weight: 400;
	line-height: 140%;
`;

const InputLabel = styled(InputLabelMUI)`
	margin-bottom: 6px;
	font-size: 13px;
	font-weight: 500;
	line-height: 130%;
	letter-spacing: -0.005em;
`;

export { ContainerExportPDF, Title, SubTitle, Input, InputLabel };
