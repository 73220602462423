import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import colors from '../../styles/colors.style';

const Btn = styled(Button)`
	color: white;
	background: ${colors.primary};
	text-align: center;
	width: auto;
	min-width: 220px;
`;

const BtnWhatsapp = styled(Button)`
	color: white;
	background: #128c7e !important;
	text-align: center;
	width: auto;
	min-width: 220px;
`;

export { Btn, BtnWhatsapp };
