import { logAmplitudeRegisterEvent } from "../service/amplitude"

const VIEW_PAGE_WELCOME_SKILLS_ASSESSMENT = 'view_page_welcome_skills_assessment'
const VIEW_PAGE_INFORMATION_SKILLS_ASSESSMENT = 'view_page_information_skills_assessment'
const VIEW_PAGE_GUIDANCE_SKILLS_ASSESSMENT = 'view_page_guidance_skills_assessment'
const VIEW_PAGE_START_SKILLS_ASSESSMENT = 'view_page_start_skills_assessment'

const VIEW_PAGE_QUESTIONS_AND_ANSWERS_SKILLS_ASSESSMENT = 'view_page_questions_and_answers_skills_assessment'

const VIEW_PAGE_CALCULATING_RESULT_SKILLS_ASSESSMENT = 'view_page_calculation_result_skills_assessment'
const VIEW_PAGE_RESULT_SKILLS_ASSESSMENT = 'view_page_result_skills_assessment'

const CLICK_SHARED_ASSESSMENT_SKILLS_ASSESSMENT = 'click_shared_assessment_skills_assessment'
const CLICK_DOWNLOAD_ASSESSMENT_PDF_SKILLS_ASSESSMENT = 'click_download_assessment_pdf_skills_assessment'
const CLICK_DIALOG_CATEGORIE_ASSESSMENT_SKILLS_ASSESSMENT = 'click_dialog_categorie_assessment_skills_assessment'

export const logViewPageWelcomeSkillsAssessment = () => {
	logAmplitudeRegisterEvent(VIEW_PAGE_WELCOME_SKILLS_ASSESSMENT)
}

export const logViewPageInformationSkillsAssessment = () => {
	logAmplitudeRegisterEvent(VIEW_PAGE_INFORMATION_SKILLS_ASSESSMENT)
}

export const logViewPageGuidanceSkillsAssessment = ({ step }) => {
	let page = ''

	if(step === 1) {
		page = 'step_1'
	} else if(step === 2) {
		page = 'step_2'
	} else if(step === 3) {
		page = 'step_3'
	}

	logAmplitudeRegisterEvent(VIEW_PAGE_GUIDANCE_SKILLS_ASSESSMENT, { page })
}

export const logViewPageStartSkillsAssessment = () => {
	logAmplitudeRegisterEvent(VIEW_PAGE_START_SKILLS_ASSESSMENT)
}

export const logViewPageQuestionsAndAnswersSkillsAssessment = ({ questionsPage }) => {
	logAmplitudeRegisterEvent(VIEW_PAGE_QUESTIONS_AND_ANSWERS_SKILLS_ASSESSMENT, { questionsPage })
}

export const logViewPageCalculatingResultSkillsAssessment = () => {
	logAmplitudeRegisterEvent(VIEW_PAGE_CALCULATING_RESULT_SKILLS_ASSESSMENT)
}

export const logViewPageResultSkillsAssessment = ({ name, email, companyName }) => {
	logAmplitudeRegisterEvent(VIEW_PAGE_RESULT_SKILLS_ASSESSMENT, { name, email, companyName })
}

export const logClickOnSharedOnResultSkillsAssessment = ({ sharedType, name, email, companyName }) => {
	logAmplitudeRegisterEvent(CLICK_SHARED_ASSESSMENT_SKILLS_ASSESSMENT, { sharedType, name, email, companyName })
}

export const logClickOnDownloadPdfOnResultSkillsAssessment = ({ name, email, companyName }) => {
	logAmplitudeRegisterEvent(CLICK_DOWNLOAD_ASSESSMENT_PDF_SKILLS_ASSESSMENT, { name, email, companyName })
}

export const logClickOnDialogResultCategorieOnResultSkillsAssessment = ({ categorieName, name, email, companyName }) => {
	logAmplitudeRegisterEvent(CLICK_DIALOG_CATEGORIE_ASSESSMENT_SKILLS_ASSESSMENT, { categorieName, name, email, companyName })
}
