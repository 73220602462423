import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import colors from '../../../styles/colors.style';
import { device } from '../../../styles/devices.style';
import fonts from '../../../styles/fonts.style';

const Container = styled(Grid)`
	/* flex-direction: start;
	flex-basis: 49% !important; */
	/* margin-left: 1%; */
	max-width: 46%;
	@media ${device.mobileL} {
		max-width: 100%;
		min-width: 100%;
	}
`;

const Img = styled.img`
	min-width: 340px;
	max-width: 340px;
	@media ${device.mobileL} {
		min-width: 240px;
		max-width: 240px;
	}
`;

const Div = styled.div`
	flex-direction: row;
	min-width: 100%;
	margin-top: 4%;
	margin-bottom: 4%;
	color: ${colors.skillsText};
`;

const Title = styled.h1`
	font-style: normal;
	font-weight: bold;
	font-size: ${fonts.biggest};
	line-height: 130%;
	color: ${colors.skillsText};
`;

const Subtitle = styled.h5`
	font-family: 'Montserrat';
	font-style: normal;
	text-transform: none;
	font-weight: bolder;
	font-size: 24px;
	line-height: 110%;
	color: #fff;
	letter-spacing: -0.01em;
	text-align: justify;
	line-break: auto;
`;

export { Container, Div, Title, Subtitle, Img };
