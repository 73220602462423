import { Dialog as DialogMUI } from '@material-ui/core';
import styled from 'styled-components';

const Dialog = styled(DialogMUI)`
	.MuiDialogContent-root {
		background: #0f1445;
		color: rgba(255, 255, 255, 0.85);
		overflow-y: scroll !important;
		&::-webkit-scrollbar {
			width: 6px;
			border-radius: 9999;
			cursor: 'pointer';
		}
		&::-webkit-scrollbar-track {
			background-color: transparent;
		}
		&::-webkit-scrollbar-thumb {
			background-color: #cccccc;
		}
	}
`;

const Title = styled.h5`
	width: 100%;
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 110%;
	letter-spacing: -0.015em;
	color: #ffffff;
	text-align: center;
	margin: 8px 0 60px 0;
`;

const SubTitle = styled.p`
	width: 100%;
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 130%;
	letter-spacing: -0.015em;
	color: #ffffff;
	text-align: left;
	margin: 18px 0px;
`;

export { Dialog, Title, SubTitle };
