import { CircularProgress, Container, Grid } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import React from 'react';
import useMediaQueries from '~/SkillsAssessment/hooks/useMediaQueries';
import Button from '../../components/Button';
import ContainerWrapper from '../../components/ContainerWrapper';
import HeaderAssessment from '../../components/HeaderAssessment';
import { Title } from './CalculatingResult.style';

const CalculatingResult = ({ isLoading, handleOpenResult }) => {
	const { isBetweenXsAndSm: isMobile } = useMediaQueries();

	React.useEffect(() => {}, []);

	return (
		<ContainerWrapper noBackgroundImage={isMobile}>
			<HeaderAssessment />
			<Container maxWidth="lg" disableGutters>
				<Grid container style={{ padding: '16px' }}>
					<Grid
						item
						xs={12}
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							flexDirection: 'column',
						}}
					>
						{isLoading ? (
							<CircularProgress />
						) : (
							<CheckIcon
								style={{ width: '40px', height: '40px', color: '#3EB27B' }}
							/>
						)}
						<Title>
							{isLoading
								? 'Aguarde... Estamos calculando seu resultado'
								: 'Seu Diagnóstico de Maturidade está pronto!'}
						</Title>
						<Button
							size="large"
							disabled={isLoading}
							onClick={handleOpenResult}
							style={{ width: '168px' }}
						>
							Ver resultado
						</Button>
					</Grid>
				</Grid>
			</Container>
			<Grid />
		</ContainerWrapper>
	);
};

export default CalculatingResult;
