import axios from 'axios';
// import { getLocalStorage } from 'utils/localStorage';

let url;

if (process.env.REACT_APP_NODE_ENV === 'production') {
	url = process.env.REACT_APP_PROD;
} else {
	url = process.env.REACT_APP_DEV;
}

// const auth = getLocalStorage('auth');
// const Authorization = auth && auth.token ? `Bearer ${auth.token}` : null;
const Authorization = `Bearer ${process.env.REACT_APP_KEY}`;

const api = axios.create({
	baseURL: url,
	headers: {
		Authorization,
		'Content-Type': 'application/json',
		'Access-Control-Allow-Origin': '*',
	},
});

export { api };
