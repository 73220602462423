import { NotificationManager } from 'react-notifications';

export const validator = (value, type) => {
	switch (type) {
		case 'name':
			if (value.length < 3) {
				NotificationManager.error(
					'Por favor, digite um nome válido com mais de 3 caracteres!'
				);
				return false;
			}
			if (value.length > 140) {
				NotificationManager.error(
					'Por favor, digite um nome válido com no máximo 140 caracteres!'
				);
				return false;
			}
			return true;
		case 'phone':
			if (value.length < 14) {
				NotificationManager.error('Por favor, digite um telefone válido!');
				return false;
			}
			if (value.length > 20) {
				NotificationManager.error('Por favor, digite um telefone válido!');
				return false;
			}
			return true;
		case 'linkedin':
			if (value.length > 200) {
				NotificationManager.error('Por favor, insira um link válido!');
				return false;
			}
			return true;
		case 'email':
			if (value.length < 6 || value.length > 160) {
				NotificationManager.error('Por favor, digite um email válido!');
				return false;
			}
			const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			if (!regex.test(String(value).toLowerCase())) {
				NotificationManager.error('Por favor, digite um email válido!');
				return false;
			}
			return true;
		default:
			if (value.length < 2) {
				NotificationManager.error(
					'Por favor, responda com no minímo 2 caracteres!'
				);
				return false;
			}
			if (value.length > 140) {
				NotificationManager.error(
					'Por favor, responda com no máximo 255 caracteres!'
				);
				return false;
			}
			return true;
	}
};
