import React from 'react';
import { Grid, Box } from '@material-ui/core';
import DOMPurify from 'dompurify';
import { Text, Title, Img } from './Welcome.style';
import Button from '../../components/Button';
import Logo from '../../assets/skillsLogo.svg';
import LogoG4Pass from '../../assets/g4pass-assessment.png';
import margins from '../../styles/margins.style';

const Welcome = ({ assessment, startAssessment }) => {
	const Html = DOMPurify.sanitize(assessment.description);
	return (
		// <Box>
			<Grid
				container
				direction="row"
				justify="space-around"
				alignItems="center"
			>
				<Grid
					container
					item
					xs={12}
					sm={6}
					justify="center"
				>
					<Box mt={margins.small} ml={margins.default} mr={margins.default}>
						<Text>
							<Img src={assessment.id == 10 ? LogoG4Pass : Logo} alt="logo" width={320} />
						</Text>
						<Title>{assessment.name}.</Title>
						<Text style={{display: 'flex', flexDirection: 'column'}}>
							<div dangerouslySetInnerHTML={{ __html: Html }} />
							<Text>Vamos começar ?</Text>
							<br />
							<span>
								Ao clicar em iniciar avaliação você automaticamente aceita os
								nossos{' '}
								<a
									target="_blank"
									style={{ color: '#F04D57' }}
									href="/termos?type=use"
								>
									termos de uso
								</a>{' '}
								e{' '}
								<a
									target="_blank"
									style={{ color: '#F04D57' }}
									href="/termos?type=policy"
								>
									política de privacidade
								</a>
								.
							</span>
							<br />
							<br />
							<br />
							<Button
								id="start_form"
								text="Iniciar Avaliação"
								onClick={async () => {
									await startAssessment();
								}}
							/>
						</Text>
					</Box>
				</Grid>
			</Grid>
		// </Box>
	);
};

export default Welcome;
