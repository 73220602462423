import {
	Box,
	Chip,
	Container,
	Divider,
	Grid,
	Typography,
} from '@material-ui/core';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import dayjs from 'dayjs';
import React from 'react';
import Footer from '~/SkillsAssessment/components/Footer';
import HeaderResult from '~/SkillsAssessment/components/HeaderResult';
import RadarChart from '~/SkillsAssessment/components/RadarChart';
import SemiCircleChart from '~/SkillsAssessment/components/SemiCircleChart';
import { useSkillsAssessmentContext } from '~/SkillsAssessment/context/SkillsAssessmentContext';
import useMediaQueries from '~/SkillsAssessment/hooks/useMediaQueries';
import { dataScore } from '~/SkillsAssessment/mock/dataScore';
import {
	SCORE_RANGE_NEGATIVE,
	SCORE_RANGE_REGULAR,
} from '~/SkillsAssessment/shared/constants';
import FormInfos from '../FormInfos';
import ResultBoxByCategory from '../ResultBoxByCategory';
import Stepper from '../Stepper';
import { Heading, SubTitle, Title } from './ExportPDF.styles';

const ExportPDF = ({
	resultsSeries,
	steps,
	infosResult,
	resultsByAreas,
	isExportPdfLoading,
}) => {
	React.useEffect(() => {}, []);
	const { isBetweenXsAndSm: isMobile } = useMediaQueries();
	const { selectedDiagnosticResult } = useSkillsAssessmentContext();

	const getScoreRange = (points) => {
		if (points < SCORE_RANGE_NEGATIVE) {
			return 'negative';
		}
		if (points < SCORE_RANGE_REGULAR) {
			return 'regular';
		}
		return 'positive';
	};

	const getChipText = (points) => {
		if (points < SCORE_RANGE_NEGATIVE) return 'RUIM';
		if (points < SCORE_RANGE_REGULAR) return 'REGULAR';
		return 'BOM';
	};

	const getChipBackgound = (points) => {
		if (points < SCORE_RANGE_NEGATIVE) return 'rgba(255, 79, 79, 0.3)';
		if (points < SCORE_RANGE_REGULAR) return 'rgba(254, 200, 62, 0.3)';
		return 'rgba(39, 217, 174, 0.3)';
	};

	return (
		<div
			className="export"
			style={{
				width: '1150px',
				height: '1650px',
				display: isExportPdfLoading ? 'block' : 'none',
				background:
					'radial-gradient(66.67% 66.67% at 50% 44.35%, #161c54 0%, #060a2f 100%)',
				overflow: 'hidden',
			}}
		>
			<Container maxWidth={false} disableGutters>
				<Grid
					container
					style={{
						height: '1700px',
					}}
				>
					<Grid item style={{ width: '100%' }}>
						<HeaderResult isRelative />
					</Grid>
					<Grid item style={{ padding: '80px' }}>
						<Grid
							item
							xs={12}
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								flexDirection: 'row',
								gap: '0px 8px',
							}}
						>
							<Grid
								item
								xs={12}
								sm={12}
								md={7}
								lg={7}
								xl={7}
								style={{ width: '100%' }}
							>
								<Heading
									style={{
										marginRight: '16px',
										marginTop: '8px',
										textAlign: 'left',
									}}
								>
									Resultado do seu Diagnóstico
								</Heading>
								<RadarChart series={resultsSeries} height="525px" />
							</Grid>
							<Grid item xs={8} style={{ marginTop: '64px' }}>
								<Title>Seu resultado está pronto!</Title>
								<Box
									style={{
										marginTop: '30px',
										width: '100%',
									}}
								>
									<Stepper steps={steps} />
								</Box>
								<Divider
									style={{
										margin: isMobile
											? '16px 0px 36px 0px'
											: '27px 0px 55px 0px',
										backgroundColor: 'rgba(255, 255, 255, 0.1)',
										width: '100%',
										height: '1px',
									}}
								/>
								<Title>Suas informações</Title>
								<Box
									style={{
										marginTop: '16px',
										width: '100%',
									}}
								>
									<FormInfos infos={infosResult} />
									<Title>{`Diagnóstico realizado em: ${dayjs(new Date()).format(
										'DD/MM/YYYY'
									)}`}</Title>
								</Box>
								<Divider
									style={{
										margin: '64px 0px 16px 0px',
										backgroundColor: 'rgba(255, 255, 255, 0.1)',
										width: '100%',
										height: '1px',
									}}
								/>
							</Grid>
						</Grid>

						<Grid
							item
							xs={12}
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'flex-start',
								flexDirection: 'column',
								height: 350,
							}}
						>
							<Title>Diagnóstico das áreas</Title>
							<ResultBoxByCategory resultData={resultsByAreas} />
						</Grid>
					</Grid>
					<Grid
						item
						style={{ width: '100%', position: 'relative', bottom: 0, left: 0 }}
					>
						<Footer />
					</Grid>
				</Grid>
				<Grid
					container
					style={{
						display: 'flex',
						justifyContent: 'center',
						paddingBottom: '64px',
					}}
				>
					{resultsByAreas?.map((resultArea) => (
						<Grid
							className="export"
							item
							xs={12}
							sm={8}
							style={{
								width: '1150px',
								height: '1650px',
								padding: '64px',
								background:
									'radial-gradient(66.67% 66.67% at 50% 44.35%, #161c54 0%, #060a2f 100%)',
							}}
						>
							<Box
								style={{
									width: '100%',
									display: 'flex',
									justifyContent: 'flex-start',
									alignItems: 'flex-start',
									flexDirection: 'column',
								}}
							>
								<Heading style={{ marginRight: '16px', marginTop: '8px' }}>
									Diagnóstico
								</Heading>
								<Chip
									label={getChipText(resultArea?.points)}
									style={{
										background: getChipBackgound(resultArea?.points),
										maxWidth: '150px',
										width: 'auto',
										color: '#fff',
									}}
								/>
							</Box>
							<Box style={{ marginBottom: '32px' }}>
								<Heading>{resultArea?.name}</Heading>
							</Box>
							<Box>
								<SemiCircleChart series={[resultArea?.points]} height="300px" />
							</Box>
							<Box style={{ display: 'flex', alignItems: 'center' }}>
								<TrackChangesIcon
									style={{ marginRight: '12px', color: '#FF6559' }}
								/>
								<SubTitle>Sua empresa hoje</SubTitle>
							</Box>
							<Typography gutterBottom>
								{
									dataScore
										?.find(
											(item) =>
												item.categorie.toLowerCase() ===
												resultArea?.name.toLowerCase()
										)
										?.diagnosticResult?.find(
											(item) =>
												item.result === getScoreRange(resultArea?.points)
										)?.yourCompanyToday
								}
							</Typography>
							<Divider
								style={{
									margin: '24px 0px',
									border: '1px solid rgba(255, 255, 255, 0.1)',
								}}
							/>
							<Box style={{ display: 'flex', alignItems: 'center' }}>
								<TrendingUpIcon
									style={{ marginRight: '12px', color: '#FF6559' }}
								/>
								<SubTitle>O que deve fazer</SubTitle>
							</Box>
							<Typography gutterBottom>
								{
									dataScore
										?.find(
											(item) =>
												item.categorie.toLowerCase() ===
												resultArea?.name.toLowerCase()
										)
										?.diagnosticResult?.find(
											(item) =>
												item.result === getScoreRange(resultArea?.points)
										)?.whatToDo
								}
							</Typography>
						</Grid>
					))}
				</Grid>
			</Container>
		</div>
	);
};

export default ExportPDF;
