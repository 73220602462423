import { Box, Container, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { logViewPageGuidanceSkillsAssessment } from '~/SkillsAssessment/events';
import useMediaQueries from '~/SkillsAssessment/hooks/useMediaQueries';
import { ReactComponent as ArrowIcon } from '../../../assets/arrowIcon.svg';
import { ReactComponent as CompassIcon } from '../../../assets/compassIcon.svg';
import { ReactComponent as GearHeadIcon } from '../../../assets/gearHeadIcon.svg';
import { ReactComponent as HeartIcon } from '../../../assets/heartIcon.svg';
import ButtonAssessmentNavigator from '../../components/ButtonAssessmentNavigator';
import ContainerWrapper from '../../components/ContainerWrapper';
import HeaderAssessment from '../../components/HeaderAssessment';
import * as route from '../../routes';
import { Title } from './Information.style';

const dataText = [
	{
		icon: <ArrowIcon />,
		description:
			'Avalie a maturidade das principais áreas de crescimento do seu negócio',
	},
	{
		icon: <CompassIcon />,
		description:
			'Saiba como construir uma jornada de desenvolvimento e melhoria para você e sua equipe',
	},
	{
		icon: <HeartIcon />,
		description: 'Obtenha um diagnóstico com oportunidades de melhoria',
	},
	{
		icon: <GearHeadIcon />,
		description: 'As perguntas corretas direcionam para as reflexões corretas',
	},
];

const Information = () => {
	const { isBetweenXsAndSm: isMobile } = useMediaQueries();
	React.useEffect(() => {}, []);

	const history = useHistory();

	const goToPreviuosPage = () => {
		history.push({ pathname: route.ROUTE_SKILLS_ASSESSMENT_WELCOME });
	};

	const goToNextPage = () => {
		logViewPageGuidanceSkillsAssessment({ step: 1 });
		history.push({ pathname: route.ROUTE_SKILLS_ASSESSMENT_GUIDANCE });
	};

	return (
		<ContainerWrapper noBackgroundImage={isMobile}>
			<HeaderAssessment />
			<Container maxWidth="lg" disableGutters>
				<Grid container style={{ padding: isMobile ? '16px' : '0px' }}>
					<Title style={{ fontSize: isMobile ? '18px' : '32px' }}>
						{isMobile ? (
							<>
								Por que o Diagnóstico de Maturidade é <br />
								importante para sua empresa?
							</>
						) : (
							<>
								Por que o Diagnóstico de Maturidade <br />é importante para sua
								empresa?
							</>
						)}
					</Title>
					<Grid
						item
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							flexDirection: isMobile ? 'column' : 'row',
							gap: '16px',
							marginBottom: isMobile ? '80px' : '0px',
						}}
					>
						{dataText?.map((item, index) => (
							<Grid
								item
								xs={12}
								sm={8}
								md={3}
								lg={3}
								xl={3}
								style={{
									display: 'flex',
									flexDirection: 'column',
									padding: index === 0 ? '40px 20px' : '26px 20px',
									background: 'rgba(255, 255, 255, 0.05)',
									border: '1px solid rgba(255, 255, 255, 0.1)',
									backdropFilter: 'blur(5px)',
									borderRadius: '4px',
									height: isMobile ? '320px' : '200px',
								}}
							>
								<Box style={{ marginBottom: '16px' }}>{item.icon}</Box>
								<Typography style={{ width: '100%', marginTop: '26px' }}>
									{item.description}
								</Typography>
							</Grid>
						))}
					</Grid>
				</Grid>
			</Container>
			{isMobile ? <Grid style={{ height: '100px' }} /> : null}
			<ButtonAssessmentNavigator {...{ goToPreviuosPage, goToNextPage }} />
		</ContainerWrapper>
	);
};

export default Information;
