import { Container, Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import uuid from 'react-uuid';
import useMediaQueries from '~/SkillsAssessment/hooks/useMediaQueries';
import { SKILLS_ASSESSMENT_KEY } from '~/SkillsAssessment/shared/constants';
import { api } from '../../../services/api';
import { checkKey, getOrigin } from '../../../utils/general';
import { getLocalStorage, setLocalStorage } from '../../../utils/localStorage';
import { getJSON } from '../../../utils/queryParams';
import ButtonAssessmentNavigator from '../../components/ButtonAssessmentNavigator';
import ContainerWrapper from '../../components/ContainerWrapper';
import HeaderAssessment from '../../components/HeaderAssessment';
import * as route from '../../routes';
import { Title } from './Start.style';

const Start = () => {
	const { isBetweenXsAndSm: isMobile } = useMediaQueries();
	const history = useHistory();
	React.useEffect(() => {}, []);

	const [assessment, setAssessment] = useState('');
	const [loading, setLoading] = useState(true);
	const [utmSource, setUtmSource] = useState('');
	const [utmMedium, setUtmMedium] = useState('');
	const [utmCampaign, setUtmCampaign] = useState('');
	const [utmCampaignId, setUtmCampaignId] = useState('');
	const [utmContent, setUtmContent] = useState('');
	const [utmFbid, setUtmFbid] = useState('');
	const [utm_json, setUtmJson] = useState({});
	const [urlLink, setUrlLink] = useState('');
	const [buttonText, setButtonText] = useState('');
	const params = useLocation().search;
	const { email } = useParams();

	const getAssessment = async () => {
		try {
			const result = await api.get(
				`/assessments/${checkKey(SKILLS_ASSESSMENT_KEY)}`
			);
			setAssessment(result.data);
		} catch (error) {
			NotificationManager.error('Não foi possível carregar esse Assessment!');
		}
		setLoading(false);
	};

	const getLead = async () => {
		try {
			await setLocalStorage('lead_data', '');
			if (email) {
				const result = await api.get(`/lead?email=${email}`);
				if (Object.keys(result.data).length !== 0) {
					await setLocalStorage('lead_data', result.data);
				} else {
					await setLocalStorage('lead_data', { email });
				}
			}
		} catch (error) {
			console.log(error);
		}
	};

	const createLead = async () => {
		try {
			const data = await getLocalStorage('utm_data');
			await api.post(`/leads`, data);
		} catch (error) {
			NotificationManager.error(
				'Opa! Ocorreu um problema e não foi possível iniciar o assessment!'
			);
		}
	};

	const startAssessment = async () => {
		setLoading(true);
		try {
			const aux = uuid();
			await setLocalStorage('utm_data', {
				uuid: aux,
				utm_source: utmSource,
				utm_medium: utmMedium,
				utm_campaign: utmCampaign,
				utm_campaign_id: utmCampaignId,
				utm_content: utmContent,
				utm_fbid: utmFbid,
				utm_json,
				origin: getOrigin(),
			});
			await setLocalStorage('linkUrl', urlLink); // save seller url
			await setLocalStorage('buttonText', buttonText); // save button text
			await createLead();
			history.push(`/${SKILLS_ASSESSMENT_KEY}/${aux}`);
		} catch (error) {
			NotificationManager.error(
				'Opa! Ocorreu um problema e não foi possível iniciar a avaliação!'
			);
		}
		setLoading(false);
	};

	React.useEffect(() => {
		const source = new URLSearchParams(params).get('utm_source');
		const medium = new URLSearchParams(params).get('utm_medium');
		const campaign = new URLSearchParams(params).get('utm_campaign');
		const campaignId = new URLSearchParams(params).get('utm_campaign_id');
		const content = new URLSearchParams(params).get('utm_content');
		const fbid = new URLSearchParams(params).get('utm_fbid');
		const link = new URLSearchParams(params).get('link');
		const buttonText = new URLSearchParams(params).get('btn');
		const json = getJSON(new URLSearchParams(params).toString());

		setUtmSource(source);
		setUtmMedium(medium);
		setUtmCampaign(campaign);
		setUtmCampaignId(campaignId);
		setUtmContent(content);
		setUtmFbid(fbid);
		setUrlLink(link);
		setButtonText(buttonText);
		setUtmJson(JSON.stringify(json));

		getLead();
		if (!assessment) getAssessment();
	}, [assessment]);

	const goToPreviuosPage = () => {
		history.push({ pathname: route.ROUTE_SKILLS_ASSESSMENT_GUIDANCE });
	};

	const goToNextPage = async () => {
		await startAssessment();
	};

	return (
		<ContainerWrapper noBackgroundImage={isMobile}>
			<HeaderAssessment />
			<Container maxWidth="lg" disableGutters>
				<Grid container style={{ padding: '16px' }}>
					<Grid
						item
						xs={12}
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<Title style={{ fontSize: isMobile ? '24px' : '40px' }}>
							Vamos começar?
						</Title>
					</Grid>
				</Grid>
			</Container>
			{isMobile ? <Grid /> : null}
			<ButtonAssessmentNavigator
				nextButtonText="Começar"
				disabledNextIcon
				{...{ goToPreviuosPage, goToNextPage }}
			/>
		</ContainerWrapper>
	);
};

export default Start;
