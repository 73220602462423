import styled from 'styled-components';

const Title = styled.h5`
	width: 100%;
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 600;
	line-height: 110%;
	letter-spacing: -0.015em;
	color: #ffffff;
	text-align: center;
	margin: 22px 0px;
`;

const SubTitle = styled.p`
	width: 100%;
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 500;
	line-height: 140%;
	letter-spacing: -0.005em;
	color: #ffffff;
	text-align: center;
	margin: 0px !important;
`;

export { Title, SubTitle };
