const colors = {
	skillsBackground: '#ebf1f7',
	skillsText: '#475059',
	skillsLink: '#fd7166',
	background: '#05060c;',
	primary: '#F04D57',
	primaryLight: '#E9734E',
	primaryDark: '#CA4159',
	secoundary: '#233140',
	success: '#03DAC6',
	warning: '#ffbb33',
	danger: '#CC0000',
	error: '#ff3333',
	white: '#fff',
	dark: '#000',
	lightDark: '#030303',
	lightGray: '#FCFCFC',
	gray: '#C8C8C8',
	title: '#F3EFEF',
	text: '#fff',
	hover: '#485b70',
};

export default colors;
