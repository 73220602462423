import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Finish from '~/pages/Finish';
import Result from '~/pages/Result';
import Subscription from '~/pages/Subscription';
import Terms from '~/pages/Terms';
import Welcome from '~/pages/Welcome';
import InformationSkillsAssessment from '~/SkillsAssessment/pages/Information';
import WelcomeSkillsAssessment from '~/SkillsAssessment/pages/Welcome';
import GuidanceSkillsAssessment from './SkillsAssessment/pages/Guidance';
import ResultSkillsAssessment from './SkillsAssessment/pages/Result';
import StartSkillsAssessment from './SkillsAssessment/pages/Start';
import * as route from './SkillsAssessment/routes';

const Routes = () => {
	React.useEffect(() => {}, [])

	return (
	<Switch>
		<Route exact path="/">
			<Redirect to="/start/maturidade" />
		</Route>

		<Route exact path="/start/skills">
			<Redirect to={route.ROUTE_SKILLS_ASSESSMENT_WELCOME} />
		</Route>
		<Route exact path={route.ROUTE_SKILLS_ASSESSMENT_WELCOME} component={WelcomeSkillsAssessment} />
		<Route exact path={route.ROUTE_SKILLS_ASSESSMENT_INFORMATION} component={InformationSkillsAssessment} />
		<Route exact path={route.ROUTE_SKILLS_ASSESSMENT_GUIDANCE} component={GuidanceSkillsAssessment} />
		<Route exact path={route.ROUTE_SKILLS_ASSESSMENT_START} component={StartSkillsAssessment} />
		<Route exact path={route.ROUTE_SKILLS_ASSESSMENT_RESULT} component={ResultSkillsAssessment} />

		<Route exact path="/start/:key" component={Welcome} />
		<Route exact path="/start/:key/:email" component={Welcome} />
		<Route exact path="/result-list/:key/:email" component={Result} />
		<Route exact path="/:key/:uuid" component={Subscription} />
		<Route exact path="/:key/:uuid/resultado" component={Finish} />
		<Route exact path="/termos" component={Terms} />
	</Switch>
)};

export default Routes;
