import styled from 'styled-components';

const Heading = styled.h2`
	width: auto;
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 400;
	font-size: 24px;
	line-height: 130%;
	color: #ffffff;
	text-align: ${(props) => props.align || 'center'};
`;

const Title = styled.p`
	width: 100%;
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 130%;
	color: #ffffff;
	text-align: left;
`;

const SubTitle = styled.p`
	width: 100%;
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 130%;
	letter-spacing: -0.015em;
	color: #ffffff;
	text-align: left;
	margin: 18px 0px;
`;

export { Heading, Title, SubTitle };
