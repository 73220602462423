import { CardMedia, Container, Grid } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import HeaderAssessment from '~/SkillsAssessment/components/HeaderAssessment';
import {
	logViewPageInformationSkillsAssessment,
	logViewPageWelcomeSkillsAssessment,
} from '~/SkillsAssessment/events';
import useMediaQueries from '~/SkillsAssessment/hooks/useMediaQueries';
import LogoG4VerticalSvg from '../../../assets/logo-g4educacao-vertical.svg';
import WebChartRadarImage from '../../../assets/webchart-welcome-page.png';
import Button from '../../components/Button';
import ContainerWrapper from '../../components/ContainerWrapper';
import * as route from '../../routes';
import { SubTitle, Title } from './Welcome.style';

const Welcome = () => {
	const { isBetweenXsAndSm: isMobile } = useMediaQueries();
	React.useEffect(() => {}, []);

	const history = useHistory();

	const goToNextPage = () => {
		logViewPageWelcomeSkillsAssessment();
		logViewPageInformationSkillsAssessment();
		history.push({ pathname: route.ROUTE_SKILLS_ASSESSMENT_INFORMATION });
	};

	return (
		<ContainerWrapper centralized={!isMobile}>
			{isMobile ? <HeaderAssessment /> : null}
			<Container maxWidth="lg" disableGutters>
				<Grid container style={{ padding: '16px' }}>
					<Grid
						item
						xs={12}
						sm={12}
						md={6}
						lg={6}
						xl={6}
						style={{
							display: 'flex',
							alignItems: 'flex-start',
							justifyContent: 'flex-start',
							flexDirection: 'column',
						}}
					>
						{!isMobile ? (
							<CardMedia
								component="img"
								alt="Logo G4 SKILLS"
								image={LogoG4VerticalSvg}
								style={{
									width: 'auto',
									height: 'auto',
									objectFit: 'cover',
								}}
							/>
						) : null}
						<Title
							variant="h4"
							style={{
								width: isMobile ? '100%' : '400px',
								fontSize: isMobile ? '21px' : '32px',
							}}
						>
							Diagnóstico de Maturidade da sua empresa
						</Title>
						<SubTitle
							style={{
								width: isMobile ? '100%' : '410px',
								marginTop: isMobile ? '16px' : '24px',
							}}
						>
							Avalie as 5 principais áreas do seu negócio e receba um relatório
							sobre o cenário atual da sua empresa.
						</SubTitle>
						{!isMobile ? (
							<Button onClick={goToNextPage}>Iniciar Diagnóstico</Button>
						) : null}
					</Grid>
					<Grid
						item
						xs={12}
						sm={12}
						md={6}
						lg={6}
						xl={6}
						style={{
							display: 'flex',
							justifyContent: 'center',
							margin: isMobile ? '32px 0px 64px 0px' : '0px 0px',
						}}
					>
						<CardMedia
							component="img"
							alt="gráfico animado"
							src={WebChartRadarImage}
							style={{
								width: isMobile ? '100%' : 'auto',
								height: 'auto',
								objectFit: 'cover',
							}}
						/>
					</Grid>
				</Grid>
				{isMobile ? <Grid item style={{ height: '80px' }} /> : null}
			</Container>
			{isMobile ? (
				<Grid
					item
					style={{
						position: 'fixed',
						width: '100%',
						display: 'flex',
						justifyContent: 'center',
						height: '80px',
						bottom: 0,
						backdropFilter: isMobile ? 'blur(5px)' : 'none',
					}}
				>
					<Button onClick={goToNextPage}>Começar diagnóstico</Button>
				</Grid>
			) : null}
		</ContainerWrapper>
	);
};

export default Welcome;
