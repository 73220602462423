import { CircularProgress, Grid } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import React from 'react';
import useMediaQueries from '~/SkillsAssessment/hooks/useMediaQueries';
import Button from '../Button';

const ButtonAssessmentNavigator = ({
	goToNextPage,
	goToPreviuosPage,
	nextButtonText = 'Próximo',
	disabledNextIcon = false,
	disabledPreviousIcon = false,
	isLoading = false,
}) => {
	const { isBetweenXsAndSm: isMobile } = useMediaQueries();
	React.useEffect(() => {}, []);

	return (
		<Grid
			container
			style={{
				padding: isMobile ? '0px 16px' : '0px 53px',
				width: '100vw',
				height: isMobile ? '80px' : '150px',
				position: isMobile ? 'fixed' : 'relative',
				bottom: 0,
				backdropFilter: isMobile ? 'blur(5px)' : 'none',
			}}
		>
			<Grid
				item
				xs={6}
				style={{
					width: '100%',
					height: '100%',
					display: 'flex',
					alignItems: isMobile ? 'flex-start' : 'center',
					justifyContent: 'flex-start',
				}}
			>
				<Button
					outlined
					startIcon={disabledPreviousIcon ? null : <ArrowBackIcon />}
					onClick={goToPreviuosPage}
				>
					Voltar
				</Button>
			</Grid>
			<Grid
				item
				xs={6}
				style={{
					width: '100%',
					display: 'flex',
					alignItems: isMobile ? 'flex-start' : 'center',
					justifyContent: 'flex-end',
				}}
			>
				<Button
					startIcon={isLoading ? <CircularProgress size={20} /> : null}
					endIcon={disabledNextIcon ? null : <ArrowForwardIcon />}
					onClick={goToNextPage}
					style={{ width: isMobile ? '109px' : '141px' }}
				>
					{nextButtonText}
				</Button>
			</Grid>
		</Grid>
	);
};

export default ButtonAssessmentNavigator;
