import { Container as ContainerMUI, Grid, Grow } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import React from 'react';
import Button from '~/SkillsAssessment/components/Button';
import ContainerWrapper from '~/SkillsAssessment/components/ContainerWrapper';
import HeaderAssessment from '~/SkillsAssessment/components/HeaderAssessment';
import Question from '~/SkillsAssessment/components/Question';
import {
	logViewPageCalculatingResultSkillsAssessment,
	logViewPageQuestionsAndAnswersSkillsAssessment,
} from '~/SkillsAssessment/events';
import useMediaQueries from '~/SkillsAssessment/hooks/useMediaQueries';
import { Container, Div, Subtitle } from './QuestionsAndAnswers.style';

const QuestionsAndAnswers = ({
	assessment,
	questions,
	handleNextStep,
	setAnswer,
	getAnswer,
	check,
	handleCheck,
	finishButton,
	onKeyPress,
	handleChange,
	disabled,
}) => {
	const { isBetweenXsAndSm: isMobile } = useMediaQueries();
	React.useEffect(() => {}, []);

	const handleNextStepWithLogAmplitude = () => {
		if (finishButton) {
			logViewPageQuestionsAndAnswersSkillsAssessment({
				questionsPage: questions[0].question_type,
			});
			logViewPageCalculatingResultSkillsAssessment();
		} else {
			logViewPageQuestionsAndAnswersSkillsAssessment({
				questionsPage: questions[0].question_type,
			});
		}
		handleNextStep();
	};

	return (
		<ContainerWrapper noBackgroundImage>
			<HeaderAssessment />
			<ContainerMUI
				maxWidth={false}
				disableGutters
				style={{ padding: isMobile ? '0px 24px' : '0px', maxWidth: '724px' }}
			>
				<Grid container>
					<Grid item justify="flex-start" alignItems="center">
						<Subtitle>{questions[0].question_type}</Subtitle>
					</Grid>
				</Grid>
				<Grid container>
					<Grid
						item
						xs={12}
						style={{
							display: 'flex',
							justify: 'center',
							alignItems: 'center',
						}}
					>
						<Grow
							in={true}
							style={{
								maxWidth: '100%',
							}}
						>
							<Container
								justify="center"
								alignItems="center"
								style={{ width: '100%' }}
							>
								{questions.map((question) => (
									<Div>
										<Question
											id={question.id}
											number={question.number}
											text={question.question_text}
											type={question.question_type}
											description={question.question_description}
											setAnswer={setAnswer}
											getAnswer={getAnswer}
											// eslint-disable-next-line
											options={eval(question.options)}
											check={check}
											handleCheck={handleCheck}
											onKeyPress={onKeyPress}
											handleChange={handleChange}
										/>
									</Div>
								))}
								<Grid style={{ textAlign: 'center', margin: '30px 0px' }}>
									<Button
										id="next_button"
										disabled={disabled}
										endIcon={
											finishButton ? <DoneAllIcon /> : <ArrowForwardIcon />
										}
										onClick={handleNextStepWithLogAmplitude}
									>
										{finishButton ? 'Concluir' : 'Avançar'}
									</Button>
								</Grid>
							</Container>
						</Grow>
					</Grid>
				</Grid>
			</ContainerMUI>
			{isMobile ? <Grid /> : null}
		</ContainerWrapper>
	);
};

export default QuestionsAndAnswers;
