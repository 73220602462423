export function maturityAssessment(list) {
	try {
		const leadData = list.filter((q) => q.question_type === 'Dados Pessoais');
		const step2 = list.filter(
			(q) => q.question_type === 'Estratégia e Governança'
		);
		const step3 = list.filter((q) => q.question_type === 'Gente e Gestão');
		const step4 = list.filter((q) => q.question_type === 'Produto');
		const step5 = list.filter((q) => q.question_type === 'Growth');
		const step6 = list.filter((q) => q.question_type === 'Sales');
		const step7 = list.filter((q) => q.question_type === 'Customer Experience');
		const step8 = list.filter((q) => q.question_type === 'Financeiro');
		const step9 = list.filter((q) => q.question_type === 'Outros');

		// mount questions json by steps
		const questions = {
			step1: leadData,
			step2,
			step3,
			step4,
			step5,
			step6,
			step7,
			step8,
			step9,
		};

		return questions;
	} catch (error) {
		console.log(error);
	}
}

export function growthAssessment(list) {
	try {
		const leadData = list.filter((q) => q.question_type === 'Dados Pessoais');
		const step2 = list.filter((q) => q.question_type === 'Aquisição');
		const step3 = list.filter((q) => q.question_type === 'Conversão');
		const step4 = list.filter((q) => q.question_type === 'Retenção');
		const step5 = list.filter((q) => q.question_type === 'Dados');
		const step6 = list.filter((q) => q.question_type === 'Cultura');

		// mount questions json by steps
		const questions = {
			step1: leadData,
			step2,
			step3,
			step4,
			step5,
			step6,
		};

		return questions;
	} catch (error) {
		console.log(error);
	}
}

export function tractionAssessment(list) {
	try {
		const leadData = list.filter((q) => q.question_type === 'Dados Pessoais');
		const step2 = list.filter((q) => q.question_type === 'Gestão de Negócios e Produto');
		const step3 = list.filter((q) => q.question_type === 'Cultura e Liderança');
		const step4 = list.filter((q) => q.question_type === 'Processos e Gestão');
		const step5 = list.filter((q) => q.question_type === 'Marketing e Growth');
		const step6 = list.filter((q) => q.question_type === 'Vendas');

		// mount questions json by steps
		const questions = {
			step1: leadData,
			step2,
			step3,
			step4,
			step5,
			step6,
		};

		return questions;
	} catch (error) {
		console.log(error);
	}
}

export function g4PassAssessment(list) {
	try {
		const leadData = list.filter((q) => q.question_type === 'Dados Pessoais');
		const step2 = list.filter((q) => q.question_type === 'Gestão de Negócios');
		const step3 = list.filter((q) => q.question_type === 'Cultura e Liderança');
		const step4 = list.filter((q) => q.question_type === 'Processos e Gestão');
		const step5 = list.filter((q) => q.question_type === 'Marketing e Growth');
		const step6 = list.filter((q) => q.question_type === 'Vendas e Experiência do Cliente');

		// mount questions json by steps
		const questions = {
			step1: leadData,
			step2,
			step3,
			step4,
			step5,
			step6,
		};

		return questions;
	} catch (error) {
		console.log(error);
	}
}

export function maturityAssessmentInitial(list) {
	try {
		const leadData = list.filter((q) => q.question_type === 'Dados Pessoais');
		const step2 = list.filter((q) => q.question_type === 'Gestão e Liderança');
		const step3 = list.filter((q) => q.question_type === 'Marketing e Vendas');
		const step4 = list.filter((q) => q.question_type === 'Customer Experience');
		const step5 = list.filter((q) => q.question_type === 'Gestão de Pessoas');
		const step6 = list.filter((q) => q.question_type === 'Finanças');

		// mount questions json by steps
		const questions = {
			step1: leadData,
			step2,
			step3,
			step4,
			step5,
			step6,
		};

		return questions;
	} catch (error) {
		console.log(error);
	}
}

export function sdrAssessment(list) {
	try {
		const step1 = list.filter((q) => q.question_type === 'Dados Pessoais');
		const step2 = list.filter((q) => q.question_type === 'Conhecimento Técnico em Vendas');
		const step3 = list.filter((q) => q.question_type === 'Gestão de Tempo / Produtividade / Organização');
		const step4 = list.filter((q) => q.question_type === 'Escuta ativa');
		const step5 = list.filter((q) => q.question_type === 'Comunicação / Oratória / Storytelling');
		const step6 = list.filter((q) => q.question_type === 'Agilidade na aprendizagem');
		const step7 = list.filter((q) => q.question_type === 'Inteligência Emocional');
		const step8 = list.filter((q) => q.question_type === 'Trabalho em Equipe');
		const step9 = list.filter((q) => q.question_type === 'Autoconhecimento');
		const step10 = list.filter((q) => q.question_type === 'Improviso / Objeções');
		const step11 = list.filter((q) => q.question_type === 'Tesão por Resultados / Movido a resultados');

		// mount questions json by steps
		const questions = {
			step1,
			step2,
			step3,
			step4,
			step5,
			step6,
			step7,
			step8,
			step9,
			step10,
			step11
		};

		return questions;
	} catch (error) {
		console.log(error);
	}
}

export function conarhAssessment(list) {
	try {
		const leadData = list.filter((q) => q.question_type === 'Dados Pessoais');
		const step2 = list.filter((q) => q.question_type === 'Gestão e Liderança');
		const step3 = list.filter((q) => q.question_type === 'Marketing e Vendas');
		const step4 = list.filter((q) => q.question_type === 'Customer Experience');
		const step5 = list.filter((q) => q.question_type === 'Gestão de Pessoas');
		const step6 = list.filter((q) => q.question_type === 'Finanças');

		// mount questions json by steps
		const questions = {
			step1: leadData,
			step2,
			step3,
			step4,
			step5,
			step6
		};

		return questions;
	} catch (error) {
		console.log(error);
	}
}

export function skillsAssessment(list) {
	try {
		const leadData = list.filter((q) => q.question_type === 'Dados Pessoais');
		const step2 = list.filter((q) => q.question_type === 'Gestão de Negócios');
		const step3 = list.filter((q) => q.question_type === 'Marketing e Growth');
		const step4 = list.filter((q) => q.question_type === 'Vendas');
		const step5 = list.filter((q) => q.question_type === 'Cultura e Liderança');
		const step6 = list.filter((q) => q.question_type === 'Experiência do Cliente');

		// mount questions json by steps
		const questions = {
			step1: leadData,
			step2,
			step3,
			step4,
			step5,
			step6
		};

		return questions;
	} catch (error) {
		console.log(error);
	}
}

export function liderancaAssessment(list) {
	try {
		const leadData = list.filter((q) => q.question_type === 'Dados Pessoais');
		const step2 = list.filter((q) => q.question_type === 'Cultura e propósito');
		const step3 = list.filter((q) => q.question_type === 'Delegação');
		const step4 = list.filter((q) => q.question_type === 'Retenção de talentos');
		const step5 = list.filter((q) => q.question_type === 'Gerenciamento de crise');

		// mount questions json by steps
		const questions = {
			step1: leadData,
			step2,
			step3,
			step4,
			step5
		};

		return questions;
	} catch (error) {
		console.log(error);
	}
}

export function gestaoDePessoasAssessment(list) {
	try {
		const leadData = list.filter((q) => q.question_type === 'Dados Pessoais');
		const step2 = list.filter((q) => q.question_type === 'Espirito de time');
		const step3 = list.filter((q) => q.question_type === 'Transparência');
		const step4 = list.filter((q) => q.question_type === 'Contratação');
		const step5 = list.filter((q) => q.question_type === 'Demissão');

		// mount questions json by steps
		const questions = {
			step1: leadData,
			step2,
			step3,
			step4,
			step5
		};

		return questions;
	} catch (error) {
		console.log(error);
	}
}

export function genteECulturaAssessment(list) {
	try {
		const leadData = list.filter((q) => q.question_type === 'Dados Pessoais');
		const step2 = list.filter((q) => q.question_type === 'Símbolo');
		const step3 = list.filter((q) => q.question_type === 'Comportamento');
		const step4 = list.filter((q) => q.question_type === 'Sistema');

		// mount questions json by steps
		const questions = {
			step1: leadData,
			step2,
			step3,
			step4
		};

		return questions;
	} catch (error) {
		console.log(error);
	}
}

