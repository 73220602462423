import { Backdrop, CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import uuid from 'react-uuid';
import withConnect from 'stores/withConnect';
import { api } from '../../services/api';
import { checkKey, getOrigin } from '../../utils/general';
import { getLocalStorage, setLocalStorage } from '../../utils/localStorage';
import { getJSON } from '../../utils/queryParams';
import Welcome from './Welcome';
import { Body } from './Welcome.style';

const WelcomeContainer = () => {
	const history = useHistory();
	const [assessment, setAssessment] = useState('');
	const [loading, setLoading] = useState(true);
	const [utmSource, setUtmSource] = useState('');
	const [utmMedium, setUtmMedium] = useState('');
	const [utmCampaign, setUtmCampaign] = useState('');
	const [utmCampaignId, setUtmCampaignId] = useState('');
	const [utmContent, setUtmContent] = useState('');
	const [utmFbid, setUtmFbid] = useState('');
	const [utm_json, setUtmJson] = useState({});
	const [urlLink, setUrlLink] = useState('');
	const [buttonText, setButtonText] = useState('');
	const params = useLocation().search;
	const { key, email } = useParams();

	const getAssessment = async () => {
		try {
			const result = await api.get(`/assessments/${checkKey(key)}`); // temp key
			setAssessment(result.data);
		} catch (error) {
			NotificationManager.error('Não foi possível carregar esse Assessment!');
		}
		setLoading(false);
	};

	const getLead = async () => {
		try {
			await setLocalStorage('lead_data', '');
			if (email) {
				const result = await api.get(`/lead?email=${email}`);
				if (Object.keys(result.data).length !== 0) {
					await setLocalStorage('lead_data', result.data);
				} else {
					await setLocalStorage('lead_data', { email });
				}
			}
		} catch (error) {
			console.log(error);
		}
	};

	const createLead = async () => {
		try {
			const data = await getLocalStorage('utm_data');
			await api.post(`/leads`, data);
		} catch (error) {
			NotificationManager.error(
				'Opa! Ocorreu um problema e não foi possível iniciar o assessment!'
			);
		}
	};

	React.useEffect(() => {
		console.log(key);
	}, [key]);

	const startAssessment = async () => {
		setLoading(true);
		try {
			const aux = uuid();
			await setLocalStorage('utm_data', {
				uuid: aux,
				utm_source: utmSource,
				utm_medium: utmMedium,
				utm_campaign: utmCampaign,
				utm_campaign_id: utmCampaignId,
				utm_content: utmContent,
				utm_fbid: utmFbid,
				utm_json,
				origin: getOrigin(),
			});
			await setLocalStorage('linkUrl', urlLink); // save seller url
			await setLocalStorage('buttonText', buttonText); // save button text
			await createLead();
			history.push(`/${key}/${aux}`);
		} catch (error) {
			NotificationManager.error(
				'Opa! Ocorreu um problema e não foi possível iniciar a avaliação!'
			);
		}
		setLoading(false);
	};

	useEffect(() => {
		const source = new URLSearchParams(params).get('utm_source');
		const medium = new URLSearchParams(params).get('utm_medium');
		const campaign = new URLSearchParams(params).get('utm_campaign');
		const campaignId = new URLSearchParams(params).get('utm_campaign_id');
		const content = new URLSearchParams(params).get('utm_content');
		const fbid = new URLSearchParams(params).get('utm_fbid');
		const link = new URLSearchParams(params).get('link');
		const buttonText = new URLSearchParams(params).get('btn');
		const json = getJSON(new URLSearchParams(params).toString());

		setUtmSource(source);
		setUtmMedium(medium);
		setUtmCampaign(campaign);
		setUtmCampaignId(campaignId);
		setUtmContent(content);
		setUtmFbid(fbid);
		setUrlLink(link);
		setButtonText(buttonText);
		setUtmJson(JSON.stringify(json));

		getLead();
		if (!assessment) getAssessment();
	}, [assessment]);

	return (
		<Body>
			{loading ? (
				<Backdrop open={loading}>
					<CircularProgress color="inherit" />
				</Backdrop>
			) : (
				<Welcome
					assessment={assessment}
					startAssessment={() => startAssessment()}
				/>
			)}
		</Body>
	);
};

export default withConnect(WelcomeContainer);
