import {
	Chip as ChipMUI,
	Dialog as DialogMUI,
	IconButton as IconButtonMUI,
} from '@material-ui/core';
import styled from 'styled-components';

const Dialog = styled(DialogMUI)`
	.MuiDialogContent-root {
		background: #0f1445;
		color: rgba(255, 255, 255, 0.85);
		overflow-y: scroll !important;
		&::-webkit-scrollbar {
			width: 6px;
			border-radius: 9999;
			cursor: 'pointer';
		}
		&::-webkit-scrollbar-track {
			background-color: transparent;
		}
		&::-webkit-scrollbar-thumb {
			background-color: #cccccc;
		}
	}
`;

const IconButton = styled(IconButtonMUI)`
	width: 40px;
	height: 40px;
	padding: 0px !important;
	background: transparent !important;
	box-shadow: none !important;
	&:hover {
		background: rgba(255, 255, 255, 0.1) !important;
	}
`;

const Title = styled.h5`
	width: 100%;
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 110%;
	letter-spacing: -0.015em;
	color: #ffffff;
	text-align: center;
	margin: 8px 0 60px 0;
`;

const SubTitle = styled.p`
	width: 100%;
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 130%;
	letter-spacing: -0.015em;
	color: #ffffff;
	text-align: left;
	margin: 18px 0px;
`;

const Chip = styled(ChipMUI)`
	height: 22px;
	border: 1px solid rgba(255, 255, 255, 0.05);
	border-radius: 10px;
	font-weight: 500;
	font-size: 16px;
	line-height: 130%;
	letter-spacing: -0.015em;
	margin-left: 8px;
	.MuiChip-label {
		padding: 0px !important;
		color: rgba(255, 255, 255, 0.75);
	}
`;

export { Dialog, IconButton, Title, SubTitle, Chip };
