import { Typography } from '@material-ui/core';
import styled from 'styled-components';

const ContainerWrapper = styled.div`
	width: 100vw;
	height: 100vh;
	margin-top: 52px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: radial-gradient(
		66.67% 66.67% at 50% 44.35%,
		#161c54 0%,
		#060a2f 100%
	);
`;

const Title = styled(Typography)`
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 600;
	font-size: 32px;
	line-height: 110%;
	letter-spacing: -0.015em;
	color: #ffffff;
`;

const SubTitle = styled(Typography)`
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 150%;
	letter-spacing: -0.005em;
	color: rgba(255, 255, 255, 0.85);
`;

export { ContainerWrapper, Title, SubTitle };
