import { Backdrop, CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { useParams } from 'react-router-dom';
import withConnect from 'stores/withConnect';
import QuestionsAndAnswers from '~/SkillsAssessment/pages/QuestionsAndAnswers';
import { api } from '../../services/api';
import { checkKey } from '../../utils/general';
import {
	conarhAssessment,
	g4PassAssessment,
	genteECulturaAssessment,
	gestaoDePessoasAssessment,
	growthAssessment,
	liderancaAssessment,
	maturityAssessment,
	maturityAssessmentInitial,
	sdrAssessment,
	skillsAssessment,
	tractionAssessment,
} from '../../utils/generateSections';
import { getLocalStorage } from '../../utils/localStorage';
import { formatCurrency, phoneMask } from '../../utils/masks';
import { validator } from '../../utils/validators';
import Subscription from './Subscription';

const SubscriptionContainer = () => {
	const [assessment, setAssessment] = React.useState('');
	const [lengthAssessment, setLengthAssessment] = useState(null);
	const [sections, setSections] = useState(null);
	const [currentStep, setCurrentStep] = useState(0);
	const [questions, setQuestions] = useState([]);
	const [answers, setAnswers] = useState([]);
	const [loading, setLoading] = useState(true);
	const [finishButton, setFinishButton] = useState(false);
	const [check, setCheck] = useState(true);
	const [disabled, setDisabled] = useState(true);
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [company, setCompany] = useState('');
	const [role, setRole] = useState('');
	const [employees, setEmployees] = useState('');
	const [annualRevenue, setAnnualRevenue] = useState('');

	const { key, uuid } = useParams();

	async function getQuestions() {
		try {
			const result = await api.get(`/assessments/${checkKey(key)}`);
			setAssessment(result.data);

			var assessmentSections = null;

			if (result.data.uuid === '5bdd473-c302-f8e8-c80c-280e2c368ea7')
				assessmentSections = maturityAssessment(result.data.questions);

			if (result.data.uuid === '6ccb675-c302-f8e8-c80c-280e2c368ea7')
				assessmentSections = maturityAssessmentInitial(result.data.questions);

			if (result.data.uuid === '4mbc473-c302-f8e8-c80c-280e2c368ea7')
				assessmentSections = growthAssessment(result.data.questions);

			if (result.data.uuid === '5bbc645-c302-f8e8-c80c-280e2c368ea7')
				assessmentSections = growthAssessment(result.data.questions);

			if (result.data.uuid === '5mbc473-c302-f8e8-c80c-280e2c368ea7')
				assessmentSections = growthAssessment(result.data.questions);

			if (result.data.uuid === '5bbc645-c302-f8e8-c80c-280e2c368ea8')
				assessmentSections = tractionAssessment(result.data.questions);

			if (result.data.uuid === 'd7f0efcd-bacc-11ec-aa9b-42010a084009')
				assessmentSections = sdrAssessment(result.data.questions);

			if (result.data.uuid === '3e134a83-bbfd-11ec-8c7e-0cc47af9885b')
				assessmentSections = conarhAssessment(result.data.questions);

			if (result.data.uuid === '5bbc645-c302-f8e8-c80c-280e2c368ea9')
				assessmentSections = g4PassAssessment(result.data.questions);

			if (result.data.uuid === '21872a47-e290-11ec-b625-42010a08400a')
				assessmentSections = liderancaAssessment(result.data.questions);

			if (result.data.uuid === '1902379c-f39a-11ec-b625-42010a08400a')
				assessmentSections = gestaoDePessoasAssessment(result.data.questions);

			if (result.data.uuid === '77679a4a-f3ba-11ec-b625-42010a08400a')
				assessmentSections = genteECulturaAssessment(result.data.questions);

			if (result.data.uuid === '69d87722-7c59-11ed-bca1-42010a08400e')
				assessmentSections = skillsAssessment(result.data.questions);

			setSections(assessmentSections);
			setCurrentStep(1);
			setLengthAssessment(result.data.sections);
			setLoading(false);
		} catch (error) {
			NotificationManager.error(
				'Opa! Ocorreu um problema e não foi possível carregar as perguntas!'
			);
		}
	}

	async function getLeadData() {
		const leadData = await getLocalStorage('lead_data');
		if (leadData) {
			setName(leadData.name);
			setEmail(leadData.email);
			setPhone(leadData.phone);
			setCompany(leadData.company);
			setRole(leadData.role);
			setEmployees(leadData.employees);
			setAnnualRevenue(leadData.annualRevenue);
		}
	}

	useEffect(() => {
		if (!assessment) getQuestions();
		getLeadData();
	}, []);

	// REMOVER
	useEffect(() => {
		console.log('assessment', assessment);
	}, [assessment]);

	useEffect(() => {
		if (name && company && role && employees) setDisabled(false);
		else setDisabled(true);
	}, [name, email, phone, company, role, employees, annualRevenue]);

	useEffect(() => {
		if (questions) {
			if (answers.length === questions.length) setDisabled(false);
			else setDisabled(true);
		}
	}, [answers]);

	useEffect(() => {
		switch (currentStep) {
			case 1:
				const { step1 } = sections;
				setQuestions(step1);
				break;
			case 2:
				const { step2 } = sections;
				setQuestions(step2);
				break;
			case 3:
				const { step3 } = sections;
				setQuestions(step3);
				break;
			case 4:
				const { step4 } = sections;
				setQuestions(step4);
				break;
			case 5:
				const { step5 } = sections;
				setQuestions(step5);
				break;
			case 6:
				const { step6 } = sections;
				setQuestions(step6);
				break;
			case 7:
				const { step7 } = sections;
				setQuestions(step7);
				break;
			case 8:
				const { step8 } = sections;
				setQuestions(step8);
				break;
			case 9:
				const { step9 } = sections;
				setQuestions(step9);
				break;
			case 10:
				const { step10 } = sections;
				setQuestions(step10);
				break;
			case 11:
				const { step11 } = sections;
				setQuestions(step11);
				break;
		}

		if (currentStep === lengthAssessment) setFinishButton(true);
	}, [currentStep]);

	const updateLead = async () => {
		try {
			const data = {
				name,
				company,
				email,
				phone,
				role,
				employees,
				annualRevenue,
			};
			await api.put(`/leads/${uuid}`, data);
		} catch (error) {
			NotificationManager.error(
				'Opa! Ocorreu um problema e não foi possível realizar o registro!'
			);
		}
	};

	const sendAnswer = async () => {
		try {
			const request = { answers, leadUuid: uuid, assessmentId: assessment.id };
			await api.post(`/answers`, request);
			setAnswers([]);
		} catch (error) {
			NotificationManager.error(
				'Opa! Ocorreu um problema e não foi possível enviar sua resposta!'
			);
		}
	};

	const handleNextStep = async () => {
		setLoading(true);
		if (currentStep === 1) {
			if (
				validator(name, 'name') &&
				(checkKey(key) == 14 || validator(email, 'email')) &&
				(checkKey(key) == 14 || validator(phone, 'phone')) &&
				validator(company, 'company') &&
				validator(role, 'role') &&
				validator(employees, 'employees') &&
				(checkKey(key) == 14 || validator(annualRevenue, 'annualRevenue'))
			) {
				await updateLead();
				setCurrentStep(currentStep + 1);
			}
		} else {
			await sendAnswer();
			setCurrentStep(currentStep + 1);
			setAnswers([]);

			if (currentStep >= lengthAssessment && assessment.id === 14) {
				// await sendEmailResult(`/${key}/${uuid}/resultado`);
				window.location.replace(`/skills/assessment/result/${uuid}`);
			} else if (currentStep >= lengthAssessment) {
				await sendEmailResult(`/${key}/${uuid}/resultado`);
				window.location.replace(`/${key}/${uuid}/resultado`);
			}
		}

		setDisabled(true);
		setLoading(false);
	};

	const setAnswer = (id, answer) => {
		// verify database id for lead generate
		switch (id) {
			case 1:
				setName(answer.target.value);
				break;
			case 2:
				setEmail(answer.target.value);
				break;
			case 3:
				setPhone(phoneMask(answer));
				break;
			case 4:
				setCompany(answer.target.value);
				break;
			case 5:
				setRole(answer.target.value);
				break;
			case 6:
				setEmployees(answer.target.value);
				break;
			case 206:
				setAnnualRevenue(formatCurrency(answer.target.value));
				break;
			default:
				break;
		}
	};

	const getAnswer = (id) => {
		// verify database id for lead generate
		switch (id) {
			case 1:
				return name;
			case 2:
				return email;
			case 3:
				return phone;
			case 4:
				return company;
			case 5:
				return role;
			case 6:
				return employees;
			case 206:
				return annualRevenue;
			default:
				break;
		}
	};

	const sendEmailResult = async (result) => {
		try {
			const request = {
				recipientEmail: email,
				recipientName: name,
				assessmentName: assessment.name,
				templateId: assessment.template_email,
				resultUrl: result,
			};
			await api.post(`/sendEmail`, request);
		} catch (error) {
			NotificationManager.error(
				'Opa! Ocorreu um problema e não foi possível enviar seu email de resultado!'
			);
		}
	};

	const onKeyPress = async (event) => {
		if (event.charCode === 13) {
			await handleNextStep();
		}
	};

	const handleCheck = () => {
		setCheck(!check);
	};

	const handleChange = (e, type, scoreVal, questionId, optionId) => {
		let option = {
			type,
			questionId,
			optionId,
			answer: e.target.value,
			score: scoreVal,
		};

		if (questionId == 5) {
			setRole(e.target.value);
		} else if (questionId == 6) {
			setEmployees(e.target.value);
		} else {
			let filteredList = answers.filter(
				(item) => item.questionId != option.questionId
			);

			filteredList.push(option);
			setAnswers(filteredList);
		}
	};

	const isAssessmentSkills = assessment.id === 14;

	if (loading) {
		return (
			<Backdrop
				open={loading}
				style={{
					background: isAssessmentSkills
						? 'radial-gradient(66.67% 66.67% at 50% 44.35%,#161c54 0%,#060a2f 100%)'
						: '#fff',
				}}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
		);
	}

	return (
		<>
			{isAssessmentSkills ? (
				<QuestionsAndAnswers
					assessment={assessment}
					questions={questions}
					getAnswer={getAnswer}
					setAnswer={setAnswer}
					handleNextStep={handleNextStep}
					finishButton={finishButton}
					check={check}
					handleCheck={handleCheck}
					disabled={disabled}
					onKeyPress={onKeyPress}
					handleChange={handleChange}
				/>
			) : (
				<Subscription
					assessment={assessment}
					questions={questions}
					getAnswer={getAnswer}
					setAnswer={setAnswer}
					handleNextStep={handleNextStep}
					finishButton={finishButton}
					check={check}
					handleCheck={handleCheck}
					disabled={disabled}
					onKeyPress={onKeyPress}
					handleChange={handleChange}
				/>
			)}
		</>
	);
};

export default withConnect(SubscriptionContainer);
