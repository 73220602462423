import React from 'react';
import { useParams } from 'react-router-dom';
import { api } from '~/services/api';
import { useSkillsAssessmentContext } from '~/SkillsAssessment/context/SkillsAssessmentContext';
import { logViewPageResultSkillsAssessment } from '~/SkillsAssessment/events';
import { removeLocalStorage } from '~/utils/localStorage';
import CalculatingResult from '../CalculatingResult';
import Result from './Result';

const ResultContainer = () => {
	const [loadingRequest, setLoadingRequest] = React.useState(true);
	const [loadingFake, setLoadingFake] = React.useState(true);
	const [isViewingResult, setIsViewingResult] = React.useState(false);
	const [results, setResults] = React.useState(null);
	const { uuid } = useParams();
	const { leadInfoResult, setLeadInfoResult } = useSkillsAssessmentContext();

	React.useEffect(() => {
		setTimeout(() => {
			setLoadingFake(false);
		}, 4000);
	}, []);

	async function getScore() {
		try {
			const resultList = await api.get(`/answers?leadUuid=${uuid}`);
			console.log(`/answers?leadUuid=${uuid}`, resultList.data);
			setResults(resultList.data);
			await clearStorage();
			setLoadingRequest(false);
		} catch (error) {
			console.error(error);
		}
	}

	async function clearStorage() {
		try {
			removeLocalStorage('utm_date');
			removeLocalStorage('lead_id');
			removeLocalStorage('subscription_id');
		} catch (error) {
			console.log(error);
		}
	}

	React.useEffect(() => {
		if (!results) getScore();
	}, []);

	React.useEffect(() => {
		if (results) {
			setLeadInfoResult({
				name: results?.lead?.name,
				email: results?.lead?.email,
				companyName: results?.lead?.company,
			});
		}
	}, [results]);

	const handleOpenResult = () => {
		logViewPageResultSkillsAssessment(leadInfoResult);
		setIsViewingResult(true);
	};

	React.useEffect(() => console.log('results', results), [results]);

	if (loadingRequest | loadingFake | !results | !isViewingResult) {
		return (
			<CalculatingResult
				isLoading={loadingRequest | loadingFake}
				handleOpenResult={handleOpenResult}
			/>
		);
	}

	return <Result results={results} />;
};

export default ResultContainer;
