import styled from 'styled-components';

const Title = styled.h5`
	width: 100%;
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 600;
	line-height: 110%;
	letter-spacing: -0.015em;
	color: #ffffff;
	text-align: center;
	margin-bottom: 28px;
`;

export { Title };
