import styled from 'styled-components';

const Title = styled.p`
	width: 100%;
	font-family: 'Montserrat';
	font-style: normal;
	line-height: 130%;
	color: #ffffff;
	text-align: left;
	padding: 0px !important;
`;

export { Title };
