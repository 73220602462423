const width = window.innerWidth;

const fonts = {
	biggest: width > 1366 ? '28px' : '18px',
	big: width > 1366 ? '26px' : '16px',
	large: width > 1366 ? '22px' : '16px',
	normal: width > 1366 ? '17px' : '14px',
	small: width > 1366 ? '14px' : '12px',
};

export default fonts;
