import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import withConnect from 'stores/withConnect';
import { CircularProgress, Backdrop } from '@material-ui/core';
import Terms from './Terms';
import { policy, use } from '../../utils/terms';

const TermsContainer = () => {
	const [loading, setLoading] = useState(true);
	const [text, setText] = useState('');
	const params = useLocation().search;

	function getTerms() {
		const type = new URLSearchParams(params).get('type');
		type === 'policy' ? setText(policy) : setText(use);
		setLoading(false);
	}

	useEffect(() => {
		getTerms();
	});

	return loading ? (
		<Backdrop open={loading}>
			<CircularProgress color="inherit" />
		</Backdrop>
	) : (
		<Terms text={text} />
	);
};

export default withConnect(TermsContainer);
