import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import SemiCircleChart from '~/SkillsAssessment/components/SemiCircleChart';
import { useSkillsAssessmentContext } from '~/SkillsAssessment/context/SkillsAssessmentContext';
import { logClickOnDialogResultCategorieOnResultSkillsAssessment } from '~/SkillsAssessment/events';
import useMediaQueries from '~/SkillsAssessment/hooks/useMediaQueries';
import {
	SCORE_RANGE_NEGATIVE,
	SCORE_RANGE_REGULAR,
} from '~/SkillsAssessment/shared/constants';
import { Categorie, Chip } from './ResultBoxByCategory.style';

const ResultBoxByCategory = ({ resultData = [] }) => {
	const { isBetweenXsAndSm: isMobile } = useMediaQueries();
	const { leadInfoResult } = useSkillsAssessmentContext();
	React.useEffect(() => {}, []);

	const {
		setIsDiagnosticResultDialogOpen,
		setSelectedDiagnosticResult,
	} = useSkillsAssessmentContext();

	const getChipText = (index) => {
		if (resultData[index].points < SCORE_RANGE_NEGATIVE) return 'RUIM';
		if (resultData[index].points < SCORE_RANGE_REGULAR) return 'REGULAR';
		return 'BOM';
	};

	const getChipBackgound = (index) => {
		if (resultData[index].points < SCORE_RANGE_NEGATIVE)
			return 'rgba(255, 79, 79, 0.3)';
		if (resultData[index].points < SCORE_RANGE_REGULAR)
			return 'rgba(254, 200, 62, 0.3)';
		return 'rgba(39, 217, 174, 0.3)';
	};

	const handleOpenDiagnosticResultDialog = (item) => {
		setIsDiagnosticResultDialogOpen(true);
		setSelectedDiagnosticResult(item);
		logClickOnDialogResultCategorieOnResultSkillsAssessment({
			categorieName: item.name,
			...leadInfoResult,
		});
	};

	return (
		<Grid
			item
			xs={12}
			style={{
				gap: '0px 12px',
				display: 'flex',
				alignItems: 'flex-start',
				justifyContent: 'space-between',
				width: '100%',
				minHeight: '170px',
				paddingBottom: '16px',
				flexBasis: 'auto',
				overflowX: isMobile ? 'scroll' : 'auto',
				overflowY: 'hidden',
				'&::-webkit-scrollbar': {
					width: 4,
					borderRadius: '20px',
					cursor: 'pointer',
				},
				'&::-webkit-scrollbar-track': {
					backgroundColor: '#D8DDE3',
				},
				'&::-webkit-scrollbar-thumb': {
					backgroundColor: '#CCCCCC',
				},
			}}
		>
			{resultData?.map((item, index) => (
				<Grid item style={{ width: '100%' }}>
					<Categorie
						key={item}
						disableRipple
						onClick={() => handleOpenDiagnosticResultDialog(item)}
					>
						<Grid
							container
							style={{
								width: '100%',
								height: '100%',
							}}
						>
							<Grid
								item
								style={{
									width: '100%',
									height: '20%',
									padding: '8px',
									display: 'flex',
									justifyContent: 'flex-end',
								}}
							>
								<Chip
									label={getChipText(index)}
									style={{
										background: getChipBackgound(index),
										padding: '0px 6px',
									}}
								/>
							</Grid>
							<Grid
								item
								style={{
									width: '100%',
									height: '60%',
									display: 'flex',
									justifyContent: 'center',
									flexDirection: 'column',
								}}
							>
								<SemiCircleChart
									series={[item.points > 100 ? 100 : item.points]}
								/>
								<Typography color="rgba(255, 255, 255, 0.75)">
									{item.name}
								</Typography>
							</Grid>
							<Grid
								item
								style={{
									background: 'rgba(255, 255, 255, 0.05)',
									width: '100%',
									height: '20%',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
								}}
							>
								<Typography color="#E6E6E6">Ver resultado</Typography>
							</Grid>
						</Grid>
					</Categorie>
				</Grid>
			))}
		</Grid>
	);
};

export default ResultBoxByCategory;
