import styled from 'styled-components';
import colors from '../../styles/colors.style';

const LinkButton = styled.a`
	background: ${colors.primary};
  color: white;
  padding: 14px 25px;
	border-radius: 20px;
	font-size: 0.875rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
	margin-bottom: 10px;
	:hover, :active {
		background-color: ${colors.primaryDark};
	}
`;

export { LinkButton };
