import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Grid } from '@material-ui/core';
import {
	Title,
	Img,
} from './Result.style';
import Logo from '../../assets/logo.png';
import TableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core/styles';
import Link from '../../components/Link';

const Finish = ({ results }) => {
	const { key } = useParams();

	return (
		<div style={{
			textAlign:"center",
			maxWidth: 500,
			margin: "auto",
			height:"100%"
		}}>
			{results.length ? 
				results.map((item) => (
					<a style={{
							color:"#FFF",
							fontSize:18,
							textDecoration:"none",
						}}
						target="_blank"
						href={`/${key}/${item.lead.uuid}/resultado`}
					><div style={{
						borderRadius:100, 
						backgroundColor:"transparent", 
						border:"solid 1px #EEE", 
						color:"#FFF",
						fontSize:16,
						boxShadow:"none",
						padding:"15px 40px",
						marginBottom:10,
						textDecoration:"none",
						fontWeight:"bold"
					}}>{`Avaliação realizada em ${item.createdAt}`}</div></a>
				))
				:
				<div style={{fontSize:22, padding: "40px 0"}}>Você não realizou nenhuma <br/>avaliação de maturidade.</div>
			}
		</div>
	);
};

export default Finish;
