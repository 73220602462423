import { Button as ButtonMUI } from '@material-ui/core';
import styled from 'styled-components';

const ButtonStyled = styled(ButtonMUI)`
	width: ${(props) => (props.outlined ? 'auto' : '100%')};
	max-width: 204px;
	height: ${(props) => (props.size === 'large' ? '56px' : '48px')};
	padding: ${(props) => (props.size === 'large' ? '16px 28px' : '12px 24px')};
	border-radius: 8px !important;
	background: ${(props) =>
		props.outlined ? 'transparent !important' : '#ff6559 !important'};
	text-shadow: none;
	box-shadow: none;
	text-transform: none !important;
	font-family: 'Montserrat';
	font-style: normal !important;
	font-weight: 600 !important;
	font-size: 14px !important;
	line-height: 24px !important;
	letter-spacing: 0.0075em !important;
	color: #ffffff;
	&:disabled {
		background: rgba(255, 255, 255, 0.1) !important;
	}
	&:hover {
		background: ${(props) =>
			props.outlined
				? 'rgba(255,255,255, 0.1) !important'
				: 'rgba(255, 101, 89, 0.85) !important'};
	}
`;

export { ButtonStyled };
