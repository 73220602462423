import { Grid, InputLabel, TextField } from '@material-ui/core';
import React from 'react';
import useMediaQueries from '~/SkillsAssessment/hooks/useMediaQueries';

const FormInfos = ({ infos = [] }) => {
	const { isBetweenXsAndSm: isMobile } = useMediaQueries();
	React.useEffect(() => {}, []);

	return (
		<Grid container style={{ gap: '0px 12px' }}>
			{infos?.map((item, index) => (
				<Grid item style={{ width: '100%' }}>
					<InputLabel
						htmlFor={item.name}
						style={{
							marginBottom: '6px',
							fontSize: isMobile ? '12px' : '14px',
							fontWeight: 500,
							lineHeight: '130%',
							letterSpacing: '-0.005em',
						}}
					>
						{item.label}
					</InputLabel>
					<TextField
						id={item.name}
						name={item.name}
						value={item.value}
						variant="outlined"
						disabled
						inputProps={{
							style: {
								padding: '0px 12px',
								color: 'rgba(255, 255, 255, 0.7)',
								height: 37,
							},
						}}
						style={{
							background: 'rgba(255, 255, 255, 0.05)',
							// border: '1px solid rgba(255, 255, 255, 0.05)',
							borderRadius: '4px',
							marginBottom: '10px',
							width: '100%',
							fontSize: '11px !important',
							fontWeight: 400,
							lineHeight: isMobile ? '140%' : '150%',
						}}
					/>
				</Grid>
			))}
		</Grid>
	);
};

export default FormInfos;
