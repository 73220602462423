import {
	Box,
	DialogContent,
	DialogTitle as DialogTitleMUI,
	Divider,
	Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import React from 'react';
import SemiCircleChart from '~/SkillsAssessment/components/SemiCircleChart';
import { useSkillsAssessmentContext } from '~/SkillsAssessment/context/SkillsAssessmentContext';
import useMediaQueries from '~/SkillsAssessment/hooks/useMediaQueries';
import { dataScore } from '~/SkillsAssessment/mock/dataScore';
import {
	SCORE_RANGE_NEGATIVE,
	SCORE_RANGE_REGULAR,
} from '~/SkillsAssessment/shared/constants';
import {
	Chip,
	Dialog,
	IconButton,
	SubTitle,
	Title,
} from './DiagnosticResultDialog.style';

function DialogTitle(props) {
	const { children, onClose, ...other } = props;
	const { selectedDiagnosticResult } = useSkillsAssessmentContext();

	const getChipText = () => {
		if (selectedDiagnosticResult?.points < SCORE_RANGE_NEGATIVE) return 'RUIM';
		if (selectedDiagnosticResult?.points < SCORE_RANGE_REGULAR)
			return 'REGULAR';
		return 'BOM';
	};

	const getChipBackgound = () => {
		if (selectedDiagnosticResult?.points < SCORE_RANGE_NEGATIVE)
			return 'rgba(255, 79, 79, 0.3)';
		if (selectedDiagnosticResult?.points < SCORE_RANGE_REGULAR)
			return 'rgba(254, 200, 62, 0.3)';
		return 'rgba(39, 217, 174, 0.3)';
	};

	return (
		<DialogTitleMUI
			style={{
				margin: 0,
				padding: '12px 24px',
				background: '#0F1445',
				color: '#FFFFFF',
			}}
			{...other}
		>
			{children}
			<Chip
				label={getChipText()}
				style={{
					background: getChipBackgound(),
					padding: '0px 6px',
				}}
			/>
			{onClose ? (
				<IconButton
					aria-label="fechar"
					onClick={onClose}
					style={{
						position: 'absolute',
						right: 8,
						top: 8,
						background: 'transparent !important',
						borderRadius: '10px !important',
						border: '1px solid rgba(255, 255, 255, 0.05) !important',
						boxShadow: 'none !important',
						'&:hover': {
							background: 'rgba(255, 255, 255, 0.1) !important',
						},
					}}
				>
					<CloseIcon />
				</IconButton>
			) : null}
		</DialogTitleMUI>
	);
}

const DiagnosticResultDialog = () => {
	const { isBetweenXsAndSm: isMobile } = useMediaQueries();
	const {
		isDiagnosticResultDialogOpen,
		setIsDiagnosticResultDialogOpen,
		selectedDiagnosticResult,
	} = useSkillsAssessmentContext();

	const getScoreRange = () => {
		if (selectedDiagnosticResult?.points < SCORE_RANGE_NEGATIVE) {
			return 'negative';
		}
		if (selectedDiagnosticResult?.points < SCORE_RANGE_REGULAR) {
			return 'regular';
		}
		return 'positive';
	};

	React.useEffect(() => {}, []);

	const handleClose = () => {
		setIsDiagnosticResultDialogOpen(false);
	};

	return (
		<Dialog
			id="diagnostic-result-dialog"
			onClose={handleClose}
			open={isDiagnosticResultDialogOpen}
			keepMounted
			fullWidth
			fullScreen={isMobile}
			PaperProps={{
				style: {
					borderRadius: isMobile ? '0px' : '10px',
					margin: isMobile ? 0 : 24,
				},
			}}
		>
			<DialogTitle onClose={handleClose}>Diagnóstico</DialogTitle>
			<DialogContent dividers>
				<Box>
					<SemiCircleChart
						series={[selectedDiagnosticResult?.points]}
						height="300px"
					/>
					<Title>{selectedDiagnosticResult?.name}</Title>
				</Box>
				<Box style={{ display: 'flex', alignItems: 'center' }}>
					<TrackChangesIcon style={{ marginRight: '12px', color: '#FF6559' }} />
					<SubTitle>Sua empresa hoje</SubTitle>
				</Box>
				<Typography gutterBottom>
					{
						dataScore
							?.find(
								(item) =>
									item.categorie.toLowerCase() ===
									selectedDiagnosticResult?.name.toLowerCase()
							)
							?.diagnosticResult?.find(
								(item) => item.result === getScoreRange()
							)?.yourCompanyToday
					}
				</Typography>
				<Divider
					style={{
						margin: '24px 0px',
						border: '1px solid rgba(255, 255, 255, 0.1)',
					}}
				/>
				<Box style={{ display: 'flex', alignItems: 'center' }}>
					<TrendingUpIcon style={{ marginRight: '12px', color: '#FF6559' }} />
					<SubTitle>O que deve fazer</SubTitle>
				</Box>
				<Typography gutterBottom>
					{
						dataScore
							?.find(
								(item) =>
									item.categorie.toLowerCase() ===
									selectedDiagnosticResult?.name.toLowerCase()
							)
							?.diagnosticResult?.find(
								(item) => item.result === getScoreRange()
							)?.whatToDo
					}
				</Typography>
			</DialogContent>
		</Dialog>
	);
};

export default DiagnosticResultDialog;
