import { useState } from 'react';
import { useUpdateEffect } from 'react-use';
import useDebounce from './useDebounce';

const useDebouncedState = ({ debounceTime = 500, initialState, callback }) => {
	const [state, setState] = useState(initialState);
	const debouncedState = useDebounce(state, debounceTime);
	useUpdateEffect(() => {
		if (callback && callback instanceof Function) callback(debouncedState);
	}, [debouncedState]);
	return { debouncedState, state, setState };
};

export default useDebouncedState;
