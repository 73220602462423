import { IconButton as IconButtonMUI } from '@material-ui/core';
import styled from 'styled-components';

const Title = styled.h6`
	width: 100%;
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 110%;
	color: #ffffff;
	margin: 0;
`;

const Text = styled.p`
	width: 100%;
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 171%;
	letter-spacing: 0.0075em;
	color: #e6e6e6;
	margin: 0;
	margin-left: 8px;
`;

const IconButton = styled(IconButtonMUI)`
	background: transparent !important;
	box-shadow: none !important;
	width: auto;
	text-transform: none !important;

	top: 0;
	bottom: 0;
	padding: 8px !important;

	span {
		margin: 0px !important;
	}

	&:hover {
		background: transparent !important;
	}
`;

export { Title, Text, IconButton };
