import { Box, Grid, Grow } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import React from 'react';
import LogoG4Pass from '../../assets/g4pass-assessment.png';
import Logo from '../../assets/skillsLogo.svg';
import Button from '../../components/Button';
import Question from '../../components/Question';
import { Container, Div, Img, Subtitle } from './Subscription.style';

const Subscription = ({
	assessment,
	questions,
	handleNextStep,
	setAnswer,
	getAnswer,
	check,
	handleCheck,
	finishButton,
	onKeyPress,
	handleChange,
	disabled,
}) => {
	React.useEffect(() => {}, []);

	return (
		<Box m={3}>
			<Grid direction="row" container="xs" xs={12}>
				<Grid container item justify="center" alignItems="center">
					<Img src={assessment.id == 10 ? LogoG4Pass : Logo} alt="logo" />
				</Grid>
				<Grid container item justify="center" alignItems="center">
					<Subtitle>{questions[0].question_type}</Subtitle>
				</Grid>
			</Grid>
			<Grid direction="row" container="xs" xs={12}>
				<Grid container item justify="center" alignItems="center">
					<Grow in={true}>
						<Container justify="center" alignItems="center">
							{questions.map((question) => (
								<Div>
									<Question
										id={question.id}
										number={question.number}
										text={question.question_text}
										type={question.question_type}
										description={question.question_description}
										setAnswer={setAnswer}
										getAnswer={getAnswer}
										// eslint-disable-next-line
										options={eval(question.options)}
										check={check}
										handleCheck={handleCheck}
										onKeyPress={onKeyPress}
										handleChange={handleChange}
									/>
								</Div>
							))}
							<Grid style={{ textAlign: 'center', marginTop: '30px' }}>
								<Button
									id="next_button"
									text={finishButton ? 'Concluir' : 'Avançar'}
									disabled={disabled}
									forwardIcon={
										finishButton ? <DoneAllIcon /> : <ArrowForwardIcon />
									}
									onClick={() => {
										handleNextStep();
									}}
								/>
							</Grid>
						</Container>
					</Grow>
				</Grid>
			</Grid>
		</Box>
	);
};

export default Subscription;
