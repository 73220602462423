import React from 'react';
import Chart from 'react-apexcharts';
import { Box, Grid } from '@material-ui/core';
import {
	Title,
	Title2,
	SubTitle,
	Text,
	Img,
	Container,
	ContainerCTA,
	LeadItem,
} from './Finish.style';
import Logo from '../../assets/skillsLogo.svg';
import LogoG4Pass from '../../assets/g4pass-assessment.png';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import Button from '../../components/Button';
import colors from '../../styles/colors.style';
import { NotificationManager } from 'react-notifications';

const Finish = ({ assessment, result, chartData, lead, diagnostics, link, links, buttonText }) => {
	const StyledTableCell = withStyles((theme) => ({
		head: {
			color: colors.skillsText,
		},
		body: {
			color: colors.skillsText,
			borderBottom: 0,
		},
	}))(TableCell);

	return (
		<Box m={3}>
			<Grid
				container
				direction="column"
				justify="space-around"
				alignItems="center"
				spacing={5}
			>
				<Grid container item xs={12} sm={8}>
					<Grid container item justify="center" alignItems="center">
						<Img src={assessment.assessment_id == 10 ? LogoG4Pass : Logo} alt="logo" />
					</Grid>
					<br />
					{assessment.assessment_id == 9 &&
						<>
							<Grid container item justify="center" alignItems="center" style={{ marginTop: '20px' }}>
								<Title2>Parabéns, você ganhou 15 dias para desenvolver seus colabodores por meio do SKILLS, a plataforma de ensino inteligente do G4 Educação.</Title2>
							</Grid>
							<Grid container item justify="center" alignItems="center" style={{ marginTop: '20px' }}>
								<Title2>Seu acesso foi enviado por email, verifique a caixa de span.</Title2>
							</Grid>
						</>
					}
				</Grid>
				<Grid container direction="row" item xs={12}>
					<Grid
						container
						direction="column"
						item
						sm={6}
						justifyContent="center"
						alignContent="center"
					>
						<Grid
							container
							direction="column"
							item
							sm={10}
							justifyContent="center"
							alignContent="center"
						>
							<Title>Resultado de {assessment.assessment_name}</Title>
							<Container>
								<LeadItem>Nome:</LeadItem>
								<Text>{lead.name}</Text>
								<br />
								<LeadItem>Empresa:</LeadItem>
								<Text>{lead.company}</Text>
								<br />
								<LeadItem>Email:</LeadItem>
								<Text>{lead.email}</Text>
							</Container>
							<SubTitle>
								Segundo nossa avaliação de maturidade esse é o nivelamento de
								áreas com maior e menor performance dentro da sua empresa hoje.
							</SubTitle>
							{diagnostics.length > 0 ? (
								<Container>
									{diagnostics.map((diagnostic) => (
										<Container>
											<Title2>{diagnostic.type}:</Title2>
											<SubTitle>{diagnostic.description}</SubTitle>
										</Container>
									))}
								</Container>
							) : null}
							<TableContainer>
								<Table size="small" aria-label="a dense table">
									<TableHead>
										<TableRow>
											<TableCell>Área</TableCell>
											<TableCell align="right">Nível</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{result.map((item) => (
											<TableRow>
												<StyledTableCell component="th" scope="row">
													{item.type}
												</StyledTableCell>
												<StyledTableCell align="right">
													{item.percent}
												</StyledTableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</Grid>
					</Grid>
					<Grid
						container
						direction="column"
						item
						sm={6}
						justifyContent="center"
					>
						<Box mt={3}>
							<Chart
								options={chartData.options}
								series={chartData.series}
								type="radar"
							/>
						</Box>
						{links.length > 0 && (
							<Grid container spacing={3} style={{ textAlign: 'center', flexDirection: 'column' }}>
								{links.map((customLink) => (
									<Grid item xs>
										<Button
											id={customLink.id}
											text={customLink.title}
											size="small"
											onClick={() => {
												if (!customLink.to_share) {
													if (customLink.url) window.open(customLink.url, '_blank');
												}
												else {
													if (navigator.share !== undefined) {
														navigator.share({
															title: 'G4 Skills',
															text: customLink.text,
															url: customLink.is_current_url ? window.location.href : customLink.url,
														})
													}
													else {
														navigator.clipboard.writeText(customLink.is_current_url ? window.location.href : customLink.url);

														NotificationManager.success(
															"",
															"Link copiado para área de transferência."
														);
													}
												}
											}}
										/>
									</Grid>
								))}
							</Grid>
						)}
						{link ? (
							<ContainerCTA>
								<Title2>Vamos melhorar a performance do seu negócio?</Title2>
								<br />
								<br />
								{buttonText ? (
									<SubTitle>
										Nossa equipe preparou aqui uma oferta incrível para levar
										seus resultados para o próximo nível.
									</SubTitle>
								) : (
									<SubTitle>
										Agende uma reunião com nossos especialistas e veja como o G4
										Educação pode ajudar o desenvolvimento dos seus negócios.
									</SubTitle>
								)}
								<br />
								<Button
									id="meeting"
									text={buttonText ? buttonText : 'Agendar reunião'}
									onClick={() => {
										window.open(link, '_blank');
									}}
								/>
							</ContainerCTA>
						) : null}
					</Grid>
				</Grid>
			</Grid>
		</Box>
	);
};

export default Finish;
