import styled from 'styled-components';

const ContainerStyled = styled.div`
	width: 100vw;
	min-height: 100vh;
	height: auto;
	display: flex;
	align-items: center;
	justify-content: ${(props) =>
		props.centralized ? 'center' : 'space-between'};
	flex-direction: column;
	background: radial-gradient(
		66.67% 66.67% at 50% 44.35%,
		#161c54 0%,
		#060a2f 100%
	);
`;

const ContentStyled = styled.div`
	width: 100vw;
	min-height: 100vh;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: ${(props) =>
		props.centralized ? 'center' : 'space-between'};
	flex-direction: column;
`;

export { ContainerStyled, ContentStyled };
