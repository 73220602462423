import React, { createContext, useState } from 'react';

export const SkillsAssessmentContext = createContext(null);

export const SkillsAssessmentContextProvider = ({ children }) => {
	const [
		isDiagnosticResultDialogOpen,
		setIsDiagnosticResultDialogOpen,
	] = useState(false);
	const [selectedDiagnosticResult, setSelectedDiagnosticResult] = useState(
		null
	);
	const [leadInfoResult, setLeadInfoResult] = useState(null);

	const clearSelectedDiagnosticResult = () => {
		setSelectedDiagnosticResult(null);
	};

	return (
		<SkillsAssessmentContext.Provider
			value={{
				isDiagnosticResultDialogOpen,
				setIsDiagnosticResultDialogOpen,
				selectedDiagnosticResult,
				setSelectedDiagnosticResult,
				clearSelectedDiagnosticResult,
				leadInfoResult,
				setLeadInfoResult,
			}}
		>
			{children}
		</SkillsAssessmentContext.Provider>
	);
};

export const useSkillsAssessmentContext = () =>
	React.useContext(SkillsAssessmentContext);
