import styled from 'styled-components';
import Radio from '@material-ui/core/Radio';
import { TextField, Checkbox } from '@material-ui/core';
import fonts from '../../styles/fonts.style';
import colors from '../../styles/colors.style';
const width = window.innerWidth;

const G4Label = styled.label`
	text-align: left;
	color: ${colors.skillsText};
	font-size: ${fonts.normal};
	font-weight: bold;
	line-height: 24px;
	letter-spacing: 0 !important;
`;

const Number = styled.label`
	text-align: left;
	color: ${colors.primary};
	font-size: ${fonts.normal};
	font-weight: bold;
	line-height: 24px;
	letter-spacing: 0 !important;
`;

const G4Input = styled(TextField)`
	.MuiOutlinedInput-root {
		font-style: normal;
		font-weight: bold;
		font-size: ${fonts.normal};
		line-height: 130%;
		color: ${colors.skillsText};
		width: auto;
		fieldset {
			border: 3px solid;
			border-color: ${colors.skillsText};
			border-radius: 39px;
		}
		&:hover fieldset {
			border-color: ${colors.primary};
			border-radius: 39px;
		}
		&.Mui-focused fieldset {
			border-color: ${colors.primary};
			border-radius: 39px;
		}
	}
`;

const SubLabel = styled.label`
	text-align: left;
	color: ${colors.skillsText};
	font-size: 14px;
	line-height: 24px;
`;

const SubSpan = styled.span`
	display: ${width < 768 ? 'none' : 'block'};
	color: ${colors.skillsText};
	border-color: ${colors.skillsText};
	font-size: 10px;
	margin: 2px 0px 0px 15px;
`;

const G4RadioButton = styled(Radio)`
	font-size: ${fonts.small};
	color: ${colors.primary};
	&.MuiRadio-colorSecondary {
		color: ${colors.primary} !important;
	}
	&.MuiRadio-colorSecondary.Mui-checked {
		color: ${colors.primary} !important;
	}
`;

const G4Checkobx = styled(Checkbox)`
	color: ${colors.primary};
	&.MuiCheckbox-colorSecondary {
		color: ${colors.primary} !important;
	}
	&.MuiCheckbox-colorSecondary.Mui-checked {
		color: ${colors.primary} !important;
	}
	
	span {
		&.MuiTypography-root MuiFormControlLabel-label MuiTypography-body1 {
			font-size: 12px !important;
			color: ${colors.skillsText};
		}
	}
`;

export {
	G4Label,
	G4Input,
	SubLabel,
	SubSpan,
	Number,
	G4RadioButton,
	G4Checkobx,
};
