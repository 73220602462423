import {
	Box,
	DialogContent,
	DialogTitle as DialogTitleMUI,
	Grid,
	TextField,
	Typography,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { EmailIcon, LinkedinIcon, WhatsappIcon } from 'react-share';
import Button from '~/SkillsAssessment/components/Button';
import { useSkillsAssessmentContext } from '~/SkillsAssessment/context/SkillsAssessmentContext';
import { logClickOnSharedOnResultSkillsAssessment } from '~/SkillsAssessment/events';
import useDebouncedState from '~/SkillsAssessment/hooks/useDebouncedState';
import useMediaQueries from '~/SkillsAssessment/hooks/useMediaQueries';
import {
	Dialog,
	EmailShareButton,
	IconButton,
	LinkedinShareButton,
	Snackbar,
	SubTitle,
	WhatsappShareButton,
} from './ShareResultDialog.style';

function DialogTitle(props) {
	const { children, onClose, ...other } = props;

	return (
		<DialogTitleMUI
			style={{
				margin: 0,
				padding: '12px 24px',
				background: '#0F1445',
				color: '#FFFFFF',
			}}
			{...other}
		>
			{children}

			{onClose ? (
				<IconButton
					aria-label="fechar"
					onClick={onClose}
					style={{
						position: 'absolute',
						right: 8,
						top: 8,
						background: 'transparent !important',
						borderRadius: '10px !important',
						border: '1px solid rgba(255, 255, 255, 0.05) !important',
						boxShadow: 'none !important',
						'&:hover': {
							background: 'rgba(255, 255, 255, 0.1) !important',
						},
					}}
				>
					<CloseIcon />
				</IconButton>
			) : null}
		</DialogTitleMUI>
	);
}

const ShareResultDialog = ({
	isShareResultDialogOpen,
	setIsShareResultDialogOpen,
}) => {
	const { isBetweenXsAndSm: isMobile } = useMediaQueries();
	const [isCopySuccessSnackOpen, setIsCopySuccessSnackOpen] = React.useState(
		false
	);
	const { leadInfoResult } = useSkillsAssessmentContext();

	React.useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	}, []);

	const { setState: setCountClickToCopySharedLink } = useDebouncedState({
		initialState: 1,
		debounceTime: 3000,
		callback: () =>
			logClickOnSharedOnResultSkillsAssessment({
				sharedType: 'sharedLink',
				...leadInfoResult,
			}),
	});

	const handleClose = () => {
		setIsShareResultDialogOpen(false);
	};

	const URL_TO_SHARE = window.location.href;

	const MSG_TO_SHARE = `Dá uma olhada no resultado do Diagnóstico de Maturidade da nossa empresa:	${URL_TO_SHARE}`;

	const handleClickToCopyClipboard = () => {
		setCountClickToCopySharedLink((prevCount) => prevCount + 1);
		setIsCopySuccessSnackOpen((oldState) => !oldState);
		navigator.clipboard.writeText(URL_TO_SHARE);
	};

	return (
		<>
			<Snackbar
				key="topCenter"
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				autoHideDuration={3000}
				open={isCopySuccessSnackOpen}
				onClose={() => setIsCopySuccessSnackOpen(false)}
				message={
					<Box style={{ display: 'flex', alignItems: 'center' }}>
						<CheckIcon style={{ color: 'white', marginRight: '6px' }} />
						<Typography>Link copiado com sucesso!</Typography>
					</Box>
				}
			/>

			<Dialog
				onClose={handleClose}
				open={isShareResultDialogOpen}
				PaperProps={{
					style: {
						borderRadius: '10px',
						margin: 24,
						minWidth: isMobile ? 'auto' : '450px',
					},
				}}
			>
				<DialogTitle onClose={handleClose}>Compartilhar</DialogTitle>
				<DialogContent dividers>
					<Grid
						item
						style={{
							width: '100%',
							height: 'auto',
							display: 'flex',
							flexItems: 'center',
							justifyContent: 'center',
							padding: '16px 0px',
							gap: isMobile ? '16px' : '8px',
						}}
					>
						<Grid
							item
							xs={4}
							style={{
								display: 'flex',
								justifyContent: 'center',
							}}
							onClick={() => {
								logClickOnSharedOnResultSkillsAssessment({
									sharedType: 'email',
									...leadInfoResult,
								});
								handleClose();
							}}
						>
							<EmailShareButton url={MSG_TO_SHARE} quote="teste">
								<EmailIcon size={64} round />
							</EmailShareButton>
						</Grid>
						<Grid
							item
							xs={4}
							style={{
								display: 'flex',
								justifyContent: 'center',
							}}
							onClick={() => {
								logClickOnSharedOnResultSkillsAssessment({
									sharedType: 'whatsapp',
									...leadInfoResult,
								});
								handleClose();
							}}
						>
							<WhatsappShareButton url={URL_TO_SHARE} quote="teste">
								<WhatsappIcon size={64} round />
							</WhatsappShareButton>
						</Grid>
						<Grid
							item
							xs={4}
							style={{
								display: 'flex',
								justifyContent: 'center',
							}}
							onClick={() => {
								logClickOnSharedOnResultSkillsAssessment({
									sharedType: 'linkedin',
									...leadInfoResult,
								});
								handleClose();
							}}
						>
							<LinkedinShareButton url={URL_TO_SHARE} quote="teste">
								<LinkedinIcon size={64} round />
							</LinkedinShareButton>
						</Grid>
					</Grid>
					<SubTitle>
						{isMobile
							? 'Compartilhe o seu diagnóstico'
							: 'Compartilhe o link do seu diagnóstico'}
					</SubTitle>
					<Box
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyItems: 'center',
							flexDirection: isMobile ? 'column' : 'row',
							marginBottom: 12,
						}}
					>
						<TextField
							id="shared-link"
							name="shared-link"
							value={URL_TO_SHARE}
							variant="outlined"
							disabled
							inputProps={{
								style: {
									padding: '0px 12px',
									color: '#fff',
									height: 37,
								},
							}}
							style={{
								background: 'rgba(255, 255, 255, 0.05)',
								border: '1px solid rgba(255, 255, 255, 0.05)',
								borderRadius: '4px',
								width: '100%',
							}}
						/>
						{isMobile ? (
							<Button
								contained
								style={{ height: 47, marginTop: 12, maxWidth: '100%' }}
								onClick={handleClickToCopyClipboard}
							>
								Copiar
							</Button>
						) : (
							<Button
								outlined
								style={{ height: 37, marginLeft: 6 }}
								onClick={handleClickToCopyClipboard}
							>
								Copiar
							</Button>
						)}
					</Box>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default ShareResultDialog;
