import { Chip as ChipMUI, IconButton } from '@material-ui/core';
import styled from 'styled-components';

const Categorie = styled(IconButton)`
	min-width: 172px;
	width: 100%;
	height: 210px;
	padding: 0px !important;
	border-radius: 10px !important;
	background: rgba(255, 255, 255, 0.05) !important;
	border: 1px solid rgba(255, 255, 255, 0.05) !important;
	box-shadow: none !important;
	&:hover {
		background: rgba(255, 255, 255, 0.1) !important;
	}

	.MuiIconButton-label {
		padding: 0px !important;
		margin: 0px !important;
		height: 100% !important;
	}
`;

const Chip = styled(ChipMUI)`
	height: 24px;
	border: 1px solid rgba(255, 255, 255, 0.05);
	border-radius: 10px;
	font-weight: 500;
	font-size: 16px;
	line-height: 130%;
	letter-spacing: -0.015em;
	.MuiChip-label {
		padding: 0px !important;
		color: rgba(255, 255, 255, 0.75);
	}
`;

export { Categorie, Chip };
