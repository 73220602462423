import { Box, Grid, Typography } from '@material-ui/core';
import React from 'react';
import useMediaQueries from '~/SkillsAssessment/hooks/useMediaQueries';
import { Title } from './Stepper.style';

const Stepper = ({ steps }) => {
	const { isBetweenXsAndSm: isMobile } = useMediaQueries();
	React.useEffect(() => {}, []);

	return (
		<Grid container>
			{steps.map((step, index) => (
				<Grid item xs={12 / steps.length} style={{ width: '100%' }}>
					<Box style={{ display: 'flex', alignItems: 'center' }}>
						<Box
							style={{
								width: '24px',
								height: '24px',
								background: '#FF6559',
								color: '#fff',
								borderRadius: 9999,
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<Typography
								style={{ color: '#fff', fontWeight: 800, fontSize: '16px' }}
							>
								{index + 1}
							</Typography>
						</Box>
						{index !== steps.length - 1 && (
							<Box
								style={{
									width: 'calc(100% - 24px)',
									height: 1,
									border: '1px dashed #FF6559',
								}}
							/>
						)}
					</Box>
					<Box
						style={{
							width: isMobile ? '80px' : '100px',
							marginTop: '18px',
							paddingRight: '8px',
						}}
					>
						<Title
							style={{
								fontSize: isMobile ? '10px' : '14px',
								fontWeight: 400,
								lineHeight: isMobile ? '140%' : '130%',
								letterSpacing: isMobile ? '0.01em' : '0.012em',
							}}
						>
							{step}
						</Title>
					</Box>
				</Grid>
			))}
		</Grid>
	);
};

export default Stepper;
