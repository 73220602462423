import styled from 'styled-components';

const Body = styled.body`
	display: flex;
	flex-direction: column;
	justify-content: center;
	color: #fff;
	line-height: 25.4;
	width: 100%;
	height: 100%;
`;

const Title = styled.h3`
	width: 100%;
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 600;
	font-size: 40px;
	line-height: 110%;
	letter-spacing: -0.015em;
	color: #ffffff;
	text-align: center;
`;

export { Body, Title };
