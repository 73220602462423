import { Box, Container, Divider, Grid } from '@material-ui/core';
import * as dayjs from 'dayjs';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import React from 'react';
import Footer from '~/SkillsAssessment/components/Footer';
import HeaderResult from '~/SkillsAssessment/components/HeaderResult';
import RadarChart from '~/SkillsAssessment/components/RadarChart';
import ShareResultDialog from '~/SkillsAssessment/components/ShareResultDialog';
import { useSkillsAssessmentContext } from '~/SkillsAssessment/context/SkillsAssessmentContext';
import {
	logClickOnDownloadPdfOnResultSkillsAssessment,
	logClickOnSharedOnResultSkillsAssessment,
} from '~/SkillsAssessment/events';
import useMediaQueries from '~/SkillsAssessment/hooks/useMediaQueries';
import ContainerWrapper from '../../components/ContainerWrapper';
import DiagnosticResultDialog from './components/DiagnosticResultDialog';
import ExportPDF from './components/ExportPDF';
import ExportPDFDialog from './components/ExportPDFDialog';
import ResultBoxByCategory from './components/ResultBoxByCategory';
import Stepper from './components/Stepper';
import { Input, InputLabel, SubTitle, Title } from './Result.style';

const steps = [
	'Veja sua pontuação por área',
	'Leia o diagnóstico das áreas',
	'Compartilhe o resultado com os seus líderes',
	'Agende para debater os resultados',
];

const Result = ({ results }) => {
	const { isBetweenXsAndSm: isMobile } = useMediaQueries();
	const [isShareResultDialogOpen, setIsShareResultDialogOpen] = React.useState(
		false
	);
	const [isExportPdfLoading, setIsExportPdfLoading] = React.useState(false);
	const { leadInfoResult } = useSkillsAssessmentContext();

	const resultsSeries =
		results?.result?.map((result) =>
			result.score > 100 ? 100 : result.score
		) ?? [];

	const resultsByAreas =
		results?.result?.map((result) => ({
			name: result.type,
			points: result.score > 100 ? 100 : result.score,
		})) ?? [];

	const getLabelInputText = (labelName) => {
		if (labelName === 'name') return 'Nome';
		if (labelName === 'email') return 'E-mail';
		if (labelName === 'company') return 'Empresa';
		return labelName;
	};

	const infosResult =
		Object.entries(results?.lead ?? {})?.map(([keyObject, valueObject]) => ({
			label: getLabelInputText(keyObject),
			name: keyObject,
			value: valueObject,
		})) ?? [];

	const handleSharedResult = () => {
		logClickOnSharedOnResultSkillsAssessment({
			sharedType: 'openDialog',
			...leadInfoResult,
		});
		setIsShareResultDialogOpen(true);
	};

	const handleDownloadPDF = async () => {
		setIsExportPdfLoading(true);
		logClickOnDownloadPdfOnResultSkillsAssessment(leadInfoResult);
		const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
		await sleep(500);

		const allElementsToExportPDF = document.querySelectorAll('.export');
		const totalElementsToExportPDF = allElementsToExportPDF.length;

		// console.log('allElementsToExportPDF', allElementsToExportPDF);

		const html2canvasConfig = {
			allowTaint: true,
			imageTimeout: 15000,
			logging: true,
			letterRendering: 1,
			scale: 2,
			useCORS: true,
		};

		const pdfOrientation = 'p';
		const pdfUnit = 'mm';
		const pdfFormat = 'a4';
		const pdf = new jsPDF(pdfOrientation, pdfUnit, pdfFormat, true);
		const imgWidth = 210;

		// Obs.: Está dando erro ao tentar exportar todas as páginas pelo For,
		// então por enquanto está sendo exportado separadamente.

		// for (let i = 0; i <= totalElementsToExportPDF; i++) {
		// 	await sleep(125);

		// 	await html2canvas(allElementsToExportPDF[i], html2canvasConfig).then(
		// 		(canvas) => {
		// 			const imgHeight = canvas.height * (imgWidth / canvas.width);
		// 			var imgData = canvas.toDataURL('image/png');

		// 			pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);

		// 			if (i < totalElementsToExportPDF - 1) {
		// 				pdf.addPage(pdfFormat, pdfOrientation);
		// 			}
		// 		}
		// 	);
		// }

		try {
			await html2canvas(allElementsToExportPDF[0], html2canvasConfig).then(
				(canvas) => {
					const imgHeight = canvas.height * (imgWidth / canvas.width);
					var imgData = canvas.toDataURL('image/png');

					pdf.addImage(
						imgData,
						'PNG',
						0,
						0,
						imgWidth,
						imgHeight,
						undefined,
						'FAST'
					);
					pdf.addPage(pdfFormat, pdfOrientation);
				}
			);

			await sleep(250);

			await html2canvas(allElementsToExportPDF[1], html2canvasConfig).then(
				(canvas) => {
					const imgHeight = canvas.height * (imgWidth / canvas.width);
					var imgData = canvas.toDataURL('image/png');

					pdf.addImage(
						imgData,
						'PNG',
						0,
						0,
						imgWidth,
						imgHeight,
						undefined,
						'FAST'
					);
					pdf.addPage(pdfFormat, pdfOrientation);
				}
			);

			await sleep(250);

			await html2canvas(allElementsToExportPDF[2], html2canvasConfig).then(
				(canvas) => {
					const imgHeight = canvas.height * (imgWidth / canvas.width);
					var imgData = canvas.toDataURL('image/png');

					pdf.addImage(
						imgData,
						'PNG',
						0,
						0,
						imgWidth,
						imgHeight,
						undefined,
						'FAST'
					);
					pdf.addPage(pdfFormat, pdfOrientation);
				}
			);

			await sleep(250);

			await html2canvas(allElementsToExportPDF[3], html2canvasConfig).then(
				(canvas) => {
					const imgHeight = canvas.height * (imgWidth / canvas.width);
					var imgData = canvas.toDataURL('image/png');

					pdf.addImage(
						imgData,
						'PNG',
						0,
						0,
						imgWidth,
						imgHeight,
						undefined,
						'FAST'
					);
					pdf.addPage(pdfFormat, pdfOrientation);
				}
			);

			await sleep(250);

			await html2canvas(allElementsToExportPDF[4], html2canvasConfig).then(
				(canvas) => {
					const imgHeight = canvas.height * (imgWidth / canvas.width);
					var imgData = canvas.toDataURL('image/png');

					pdf.addImage(
						imgData,
						'PNG',
						0,
						0,
						imgWidth,
						imgHeight,
						undefined,
						'FAST'
					);
					pdf.addPage(pdfFormat, pdfOrientation);
				}
			);

			await sleep(250);

			await html2canvas(allElementsToExportPDF[5], html2canvasConfig).then(
				(canvas) => {
					const imgHeight = canvas.height * (imgWidth / canvas.width);
					var imgData = canvas.toDataURL('image/png');

					pdf.addImage(
						imgData,
						'PNG',
						0,
						0,
						imgWidth,
						imgHeight,
						undefined,
						'FAST'
					);
				}
			);

			pdf.save(
				`G4 SKILLS Diagnóstico - ${results?.lead?.company} - ${dayjs(
					new Date()
				).format('DD-MM-YYYY')}.pdf`
			);
		} catch (error) {
			console.log('error', error);
		} finally {
			setIsExportPdfLoading(false);
		}
	};

	return (
		<>
			<DiagnosticResultDialog />
			<ShareResultDialog
				{...{ isShareResultDialogOpen, setIsShareResultDialogOpen }}
			/>
			<ExportPDFDialog {...{ isExportPdfLoading }} />

			<ContainerWrapper noBackgroundImage>
				<HeaderResult
					shareOnClick={handleSharedResult}
					downloadOnClick={handleDownloadPDF}
				/>
				<Container
					maxWidth="xl"
					disableGutters
					style={{
						display: 'flex',
						justifyContent: 'center',
					}}
				>
					<Grid
						item
						xs={12}
						sm={10}
						style={{
							marginTop: '56px',
							padding: '24px 16px 16px 16px',
						}}
					>
						<Grid item xs={12}>
							<Title
								style={{
									fontSize: isMobile ? '16px' : '18px',
								}}
							>
								Seu resultado está pronto!
							</Title>
						</Grid>
						<Grid
							item
							xs={12}
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								flexDirection: isMobile ? 'column' : 'row',
								gap: '0px 8px',
							}}
						>
							<Grid
								item
								xs={12}
								sm={12}
								md={5}
								lg={5}
								xl={5}
								style={{
									width: '100%',
									display: 'flex',
									alignItems: 'center',
									flexDirection: 'column',
								}}
							>
								<Box
									style={{
										width: '100%',
										marginTop: isMobile ? '16px' : '0px',
									}}
								>
									<Stepper steps={steps} />
								</Box>
								<Divider
									style={{
										margin: '16px 0px',
										backgroundColor: 'rgba(255, 255, 255, 0.1)',
										width: '100%',
										height: '1px',
									}}
								/>
								<Title style={{ fontSize: isMobile ? '16px' : '18px' }}>
									Suas informações
								</Title>
								<Box
									style={{
										marginTop: '16px',
										width: '100%',
									}}
								>
									<Grid
										item
										xs={12}
										style={{ display: 'flex', gap: '0px 12px' }}
									>
										<Grid item xs={6}>
											<InputLabel htmlFor="name">Nome</InputLabel>
											<Input
												id="name"
												name="name"
												value={results?.lead?.name}
												variant="outlined"
												disabled
												inputProps={{
													style: {
														padding: '0px 12px',
														color: 'rgba(255, 255, 255, 0.5)',
														height: 37,
													},
												}}
											/>
										</Grid>
										<Grid item xs={6}>
											<InputLabel htmlFor="email">E-mail</InputLabel>
											<Input
												id="email"
												name="email"
												value={results?.lead?.email}
												variant="outlined"
												disabled
												inputProps={{
													style: {
														padding: '0px 12px',
														color: 'rgba(255, 255, 255, 0.5)',
														height: 37,
													},
												}}
											/>
										</Grid>
									</Grid>
									<Grid container style={{ marginTop: '12px' }}>
										<Grid item xs={12} style={{ width: '100%' }}>
											<InputLabel htmlFor="company">Empresa</InputLabel>
											<Input
												id="company"
												name="company"
												value={results?.lead?.company}
												variant="outlined"
												disabled
												inputProps={{
													style: {
														padding: '0px 12px',
														color: 'rgba(255, 255, 255, 0.5)',
														height: 37,
													},
												}}
											/>
										</Grid>
									</Grid>
									{results?.lead?.createdAt ? (
										<SubTitle
											style={{ marginTop: '8px' }}
										>{`Diagnóstico realizado em: ${dayjs(
											results?.lead?.createdAt
										).format('DD/MM/YYYY')}`}</SubTitle>
									) : null}
								</Box>
							</Grid>
							<Grid
								item
								xs={12}
								sm={12}
								md={7}
								lg={7}
								xl={7}
								style={{ width: '100%' }}
							>
								<RadarChart series={resultsSeries} />
							</Grid>
						</Grid>
						<Grid item xs={12} style={{ marginTop: '-12px' }}>
							<Divider
								style={{
									margin: '16px 0px',
									backgroundColor: 'rgba(255, 255, 255, 0.1)',
									width: '100%',
									height: '1px',
								}}
							/>
							<Grid
								item
								xs={12}
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'flex-start',
									flexDirection: 'column',
									height: 350,
								}}
							>
								<Title
									style={{
										fontSize: isMobile ? '16px' : '18px',
										paddingBottom: '16px',
									}}
								>
									Diagnóstico das áreas
								</Title>
								<ResultBoxByCategory resultData={resultsByAreas} />
							</Grid>
						</Grid>
					</Grid>
				</Container>
				<Footer />
			</ContainerWrapper>

			<ExportPDF
				{...{
					resultsSeries,
					steps,
					infosResult,
					resultsByAreas,
					isExportPdfLoading,
				}}
			/>
		</>
	);
};

export default Result;
