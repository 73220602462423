export default {
	loaded: false,
	currentExample: '',
	currentExamples: [],
	total: 0,
	pages: 0,
	currentPage: 1,
	error: {},
	success: '',
};
